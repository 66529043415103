import React, { useEffect, useMemo, useState } from "react";
import Auxiliary from "../../../hoc/Auxiliary";
import ApiService from "../../../services/ApiService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlertService from "../../../services/AlertService";

import TranslationService from "../../../services/TranslationService";
import { addSpinner, removeSpinner } from "../../../store/actions/spinner";
import { v4 as uuidv4 } from "uuid";
import SkeletonComponent from "../../../components/Skeleton/SkeletonComponent";
import mainDecor from "./../../../assets/images/decoration/main-decor.svg";
import arrowLeft from "./../../../assets/icons/arrow/arrow-left-main.svg";

export default function ShortCourseMemberApplicationForm(props) {
	const [isDisabledButton, setIsDisabledButton] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const { courseId } = useParams();
	const navigate = useNavigate();
	const [isDisabledFields, setIsDisabledFields] = useState(false);
	const { language } = useSelector(state => state.language);
	const dispatch = useDispatch();
	const { spinners } = useSelector(state => state.spinners);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [errors, setErrors] = useState({});

	const [values, setValues] = useState({
		name: "",
		email: "",
		phoneNumber: "",
		education: "",
		educationOther: "",
		educationalInstitution: "",
		placeOfWork: "",
		referralSource: "",
		referralSourceOther: "",
		questions: "",
	});
	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const translationValues = useMemo(() => {
		if (translationService?.translate) {
			return {
				high_school: translationService.translate("TR_SECONDARY_(HIGH SCHOOL)"),
				professional: translationService.translate(
					"TR_SECONDARY_PROFESSIONAL_(COLLEGE,_TECHNICAL_SCHOOL)",
				),
				bachelor: translationService.translate("TR_UNDERGRADUATE_(BACHELOR)"),
				higher: translationService.translate("TR_HIGHER_(MASTER'S_DEGREE)"),
				post_graduate: translationService.translate("TR_POST_GRADUATE"),
				other: translationService.translate("TR_OTHER"),
				friends_family: translationService.translate("TR_FRIENDS/FAMILY"),
				social_media: translationService.translate("TR_SOCIAL_MEDIA"),
				advertisement: translationService.translate("TR_ADVERTISEMENT"),
			};
		}
		return {
			high_school: "",
			professional: "",
			bachelor: "",
			higher: "",
			post_graduate: "",
			other: "",
			friends_family: "",
			social_media: "",
			advertisement: "",
		};
	}, [language, translationService]);

	const onChange = (event, name, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		if (errors.hasOwnProperty(name) && event.target.value.trim().length) {
			let newErrors = { ...errors };
			delete newErrors[name];
			if (name === "educationOther" && errors.hasOwnProperty("education")) {
				delete newErrors.education;
			} else if (
				name === "referralSourceOther" &&
				errors.hasOwnProperty("referralSource")
			) {
				delete newErrors.referralSource;
			}
			setErrors(newErrors);
		}
		setValues(prevState => ({
			...prevState,
			[name]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};
	const onChangeRadio = (event, deleteName) => {
		if (errors.hasOwnProperty(event.target.name)) {
			let newErrors = { ...errors };
			delete newErrors[event.target.name];
			if (
				event.target.name === "education" &&
				errors.hasOwnProperty("educationOther")
			) {
				delete newErrors.educationOther;
			} else if (
				event.target.name === "referralSource" &&
				errors.hasOwnProperty("referralSourceOther")
			) {
				delete newErrors.referralSourceOther;
			}
			setErrors(newErrors);
		}
		setValues(value => ({
			...value,
			[event.target.name]: event.target.value,
		}));
	};

	const onSubmit = event => {
		event && event.preventDefault();
		let errors = {};
		Object.entries(values).forEach(([key, value]) => {
			switch (key) {
				case "name":
					if (!value.trim().length) {
						errors[key] = true;
					}
					return;
				case "email":
					if (
						!value.trim().length ||
						!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)
					) {
						errors[key] = true;
					}
					return;
				case "phoneNumber":
					if (!value.trim().length) {
						errors[key] = true;
					}
					return;
				case "educationalInstitution":
					if (!value.trim().length) {
						errors[key] = true;
					}
					return;
				case "education":
					if (!value.trim().length) {
						errors[key] = true;
					} else if (
						value === "other" &&
						!values.educationOther.trim().length
					) {
						errors[key] = true;
						errors.educationOther = true;
					}
					return;
				case "referralSource":
					if (!value.trim().length) {
						errors[key] = true;
					} else if (
						value === "other" &&
						!values.referralSourceOther.trim().length
					) {
						errors[key] = true;
						errors.referralSourceOther = true;
					}
					return;
				default:
					return;
			}
		});
		setErrors(errors);
		if (Object.keys(errors).length) {
			return;
		}
		setIsDisabledButton(true);
		const data = {
			courseId: +courseId,
		};
		Object.entries(values).forEach(([key, value]) => {
			if (key === "educationOther" || key === "referralSourceOther") {
				return;
			} else if (key === "education") {
				if (value === "other") {
					data[key] = `${translationValues[value]} ${values.educationOther}`;
				} else {
					data[key] = translationValues[value];
				}
			} else if (key === "referralSource") {
				if (value === "other") {
					data[
						key
					] = `${translationValues[value]} ${values.referralSourceOther}`;
				} else {
					data[key] = translationValues[value];
				}
			} else {
				data[key] = value ? value : null;
			}
		});
		ApiService.createCourseRegistrationApplication(data)
			.then(response => {
				AlertService.alert("success", "Data saved");
				setIsDisabledFields(false);
				setValues({
					name: "",
					email: "",
					phoneNumber: "",
					education: "",
					educationOther: "",
					educationalInstitution: "",
					placeOfWork: "",
					referralSource: "",
					referralSourceOther: "",
					questions: "",
				});
				setIsDisabledButton(false);
				navigate(`/${language}/course/${courseId}`);
			})
			.catch(error => getFail(error));
	};
	const getFail = error => {
		error && AlertService.alert("error", error);
		setIsDisabledFields(false);
		setIsDisabledButton(false);
	};
	if (spinners && spinners.length) {
		return (
			<section className="section">
				<div className="container-fluid overflow-hidden p-0">
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={300}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={400}
								titles={0}
								descs={0}
							/>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={700}
								titles={0}
								descs={0}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
	return translationService ? (
		<Auxiliary>
			<section className="section position-relative">
				<img className="cover-decoration" src={mainDecor} alt="/" />
				<div className="container-fluid">
					{/* <div className="container-fluid">
						<Link className="mb-3 d-block" to={`/${language}/courses`}>
							<img src={arrowLeft} alt="arrow-left-main" />
							<b>{translationService.translate("TR_BACK")}</b>
						</Link>
					</div> */}
					<div className="row">
						<div className="col-12">
							<div className="text-center max-w-700 m-auto">
								<div className="text-start word-break-break-word">
									<form onSubmit={onSubmit}>
										<h2 className="h2-m mb-4">
											{translationService.translate("TR_SEND_US_A_REQUEST")}
										</h2>
										<div className="mb-4">
											<label htmlFor="name">
												<small
													className={`mb-1 ${errors?.name ? "text_error" : ""}`}
													style={{ fontWeight: "700" }}>
													{`${translationService.translate(
														"TR_NAME",
													)} ${translationService.translate("TR_SURNAME")}`}
												</small>
											</label>
											<input
												id="name"
												type="text"
												className={`form-control`}
												placeholder={`${translationService.translate(
													"TR_NAME",
												)} ${translationService.translate("TR_SURNAME")}`}
												value={values.name}
												onChange={event => onChange(event, "name", 255)}
											/>
										</div>
										<div className="mb-4">
											<label htmlFor="email">
												<small
													className={`mb-1 ${
														errors?.email ? "text_error" : ""
													}`}
													style={{ fontWeight: "700" }}>
													{translationService.translate("TR_YOUR_EMAIL")}
												</small>
											</label>
											<input
												id="email"
												type="email"
												className="form-control"
												placeholder={translationService.translate(
													"TR_YOUR_EMAIL",
												)}
												value={values.email}
												onChange={event => onChange(event, "email", 255)}
											/>
										</div>
										<div className="mb-4">
											<label htmlFor="phone">
												<small
													className={`mb-1 ${
														errors?.phoneNumber ? "text_error" : ""
													}`}
													style={{ fontWeight: "700" }}>
													{translationService.translate("TR_START_WITH_PLUS")}
												</small>
											</label>
											<input
												id="phone"
												type="number"
												className="form-control"
												placeholder="+374 xx xxx xxx"
												value={values.phoneNumber}
												onChange={event => onChange(event, "phoneNumber", 255)}
											/>
										</div>
										<div className="mb-4">
											<small
												className={`mb-1 ${
													errors?.education ? "text_error" : ""
												}`}
												style={{ fontWeight: "700" }}>
												{translationService.translate("TR_EDUCATION")}
											</small>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													id="high_school"
													name="education"
													value={"high_school"}
													checked={values.education === "high_school"}
													onChange={onChangeRadio}
												/>
												<label
													className="form-check-label"
													htmlFor="high_school">
													{translationService.translate(
														"TR_SECONDARY_(HIGH SCHOOL)",
													)}
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													id="professional"
													name="education"
													value={"professional"}
													checked={values.education === "professional"}
													onChange={onChangeRadio}
												/>
												<label
													className="form-check-label"
													htmlFor="professional">
													{translationService.translate(
														"TR_SECONDARY_PROFESSIONAL_(COLLEGE,_TECHNICAL_SCHOOL)",
													)}
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													id="bachelor"
													name="education"
													value={"bachelor"}
													checked={values.education === "bachelor"}
													onChange={onChangeRadio}
												/>
												<label className="form-check-label" htmlFor="bachelor">
													{translationService.translate(
														"TR_UNDERGRADUATE_(BACHELOR)",
													)}
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													id="higher"
													name="education"
													value={"higher"}
													checked={values.education === "higher"}
													onChange={onChangeRadio}
												/>
												<label className="form-check-label" htmlFor="higher">
													{translationService.translate(
														"TR_HIGHER_(MASTER'S_DEGREE)",
													)}
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													id="post_graduate"
													name="education"
													value={"post_graduate"}
													checked={values.education === "post_graduate"}
													onChange={onChangeRadio}
												/>
												<label
													className="form-check-label"
													htmlFor="post_graduate">
													{translationService.translate("TR_POST_GRADUATE")}
												</label>
											</div>
											<div className="form-check gap-2">
												<input
													className="form-check-input"
													type="radio"
													id="educationOther"
													name="education"
													value={"other"}
													checked={values.education === "other"}
													onChange={onChangeRadio}
												/>
												<label
													className="form-check-label d-flex flex-column gap-2 text-nowrap"
													htmlFor="educationOther">
													{translationService.translate("TR_OTHER")}
												</label>
												{values.education === "other" && (
													<textarea
														className={`w-100 mt-2 form-control ${
															errors?.educationOther ? "border_error" : ""
														}`}
														style={{ resize: "none" }}
														placeholder={translationService.translate(
															"TR_TELL_US_ABOUT_YOURSELF",
														)}
														rows="2"
														value={values.educationOther}
														onChange={event =>
															onChange(event, "educationOther", 200)
														}
													/>
												)}
											</div>
										</div>
										<div className="mb-4">
											<label htmlFor="educational_institution">
												<small
													className={`mb-1 ${
														errors?.educationalInstitution ? "text_error" : ""
													}`}
													style={{ fontWeight: "700" }}>
													{translationService.translate(
														"TR_EDUCATIONAL_INSTITUTION",
													)}
												</small>
											</label>
											<input
												id="educational_institution"
												type="text"
												className="form-control"
												placeholder={translationService.translate(
													"TR_EDUCATIONAL_INSTITUTION_PLACEHOLDER",
												)}
												value={values.educationalInstitution}
												onChange={event =>
													onChange(event, "educationalInstitution", 255)
												}
											/>
										</div>
										<div className="mb-4">
											<label htmlFor="place_of_work">
												<small
													className={`mb-1 ${
														errors?.placeOfWork ? "text_error" : ""
													}`}
													style={{ fontWeight: "700" }}>
													{translationService.translate("TR_PLACE_OF_WORK")}
												</small>
											</label>
											<input
												type="text"
												id="place_of_work"
												className="form-control"
												placeholder={translationService.translate(
													"TR_PLACE_OF_WORK_PLACEHOLDER",
												)}
												value={values.placeOfWork}
												onChange={event => onChange(event, "placeOfWork", 255)}
											/>
										</div>
										<div className="mb-4">
											<small
												className={`mb-1 ${
													errors?.referralSource ? "text_error" : ""
												}`}
												style={{ fontWeight: "700" }}>
												{translationService.translate(
													"TR_WHERE_DID_YOU_HEAR_ABOUT_US",
												)}
											</small>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													id="advertisement"
													name="referralSource"
													value={"advertisement"}
													checked={values.referralSource === "advertisement"}
													onChange={onChangeRadio}
												/>
												<label
													className="form-check-label"
													htmlFor="advertisement">
													{translationService.translate("TR_ADVERTISEMENT")}
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													id="social_media"
													name="referralSource"
													value={"social_media"}
													checked={values.referralSource === "social_media"}
													onChange={onChangeRadio}
												/>
												<label
													className="form-check-label"
													htmlFor="social_media">
													{translationService.translate("TR_SOCIAL_MEDIA")}
												</label>
											</div>
											<div className="form-check">
												<input
													className="form-check-input"
													type="radio"
													id="friends/family"
													name="referralSource"
													value={"friends_family"}
													checked={values.referralSource === "friends_family"}
													onChange={onChangeRadio}
												/>
												<label
													className="form-check-label"
													htmlFor="friends/family">
													{translationService.translate("TR_FRIENDS/FAMILY")}
												</label>
											</div>
											<div className="form-check gap-2">
												<input
													className="form-check-input"
													type="radio"
													id="referralSourceOther"
													name="referralSource"
													value={"other"}
													checked={values.referralSource === "other"}
													onChange={onChangeRadio}
												/>
												<label
													className="form-check-label d-flex flex-column gap-2 text-nowrap"
													htmlFor="referralSourceOther">
													{translationService.translate("TR_OTHER")}
												</label>
												{values.referralSource === "other" && (
													<textarea
														className={`w-100 mt-2 form-control ${
															errors?.referralSourceOther ? "border_error" : ""
														}`}
														style={{ resize: "none" }}
														placeholder={translationService.translate(
															"TR_TELL_US_ABOUT_YOURSELF",
														)}
														rows="2"
														value={values.referralSourceOther}
														onChange={event =>
															onChange(event, "referralSourceOther", 200)
														}
													/>
												)}
											</div>
										</div>
										<div className="mb-4">
											<label htmlFor="questions">
												<small style={{ fontWeight: "700" }}>
													{translationService.translate("TR_NOTE")}
												</small>
											</label>
											<textarea
												className={`w-100 form-control`}
												style={{ resize: "none" }}
												placeholder={translationService.translate(
													"TR_NOTE_PLACEHOLDER",
												)}
												id="questions"
												rows="4"
												value={values.questions}
												onChange={event => onChange(event, "questions", 2000)}
											/>
										</div>
										<div className="d-flex justify-content-end">
											<button
												type="submit"
												className={`
                        						button primary--btn py-2
                         						${
																			Object.keys(errors).length ||
																			isDisabledButton
																				? "disabled--btn pe-none"
																				: ""
																		}`}>
												{translationService.translate("TR_SEND")}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Auxiliary>
	) : null;
}
