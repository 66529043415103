import { ADD_SPINNER, REMOVE_SPINNER } from "../../constants/reduxKeys";

const initialState = {
  spinners: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SPINNER:
      return {
        ...state,
        spinners: [...state.spinners, action.payload]
      };
    case REMOVE_SPINNER:
      return {
        ...state,
        spinners: state.spinners.filter(data => data !== action.payload)
      };

    default:
      break;
  }
  return state;
};

export default reducer;