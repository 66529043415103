import React from 'react'
import mainDecor from "./../../assets/images/decoration/main-decor.svg"
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'

export default function Skeleton_Section_1({ sectionClassName = "" }) {

  return <section className={`section-top position-relative ${sectionClassName}`}>
    <img className="cover-decoration" src={mainDecor} alt="/" />
    <div className="container-fluid">
      <div className="row align-items-center">

        <div className="col-md-5">
          <div className='ardy--title-wrapper'>
            <SkeletonComponent
              images={0}
              descs={3}
              titleHeight={150}
            />
            <SkeletonComponent
              images={0}
              descs={0}
              titleHeight={50}
              maxWith='50%'
              position='left'
            />
          </div>
        </div>

        <div className={`col-md-7 order-md-last order-first mb-5 mb-md-0`}>
          <div className="cover-ardy--section-image-wrapper d-flex justify-content-end" style={{ height: "400px", minHeight: "400px" }}>
            <SkeletonComponent
              titles={0}
              descs={0}
              imageHeight={400}
            />
          </div>
        </div>

      </div>
    </div>
  </section>
}