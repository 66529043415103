import { LANGUAGE_SUCCESS, GET_LANGUAGES_SUCCESS } from "../../constants/reduxKeys";

export function setLanguage(language) {
  return {
    type: LANGUAGE_SUCCESS,
    payload: language,
  };
};

export function setLanguages(data) {
  return {
    type: GET_LANGUAGES_SUCCESS,
    payload: data,
  };
};
