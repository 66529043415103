import React, { useEffect, useState } from 'react'
import Auxiliary from './hoc/Auxiliary'
import Header from './components/headers/Header'
import Footer from './components/footers/Footer'
import Routing from './Routing'
import { useLocation } from 'react-router-dom'
import HeaderWithBackButton from './components/headers/HeaderWithBackButton'

export default function Layout() {
  const location = useLocation();
  const [isShowMainLayout, setIsShowMainLayout] = useState(true);

  useEffect(() => {
    switch (true) {
      case location.pathname.includes("/terms-and-conditions"):
      case location.pathname.includes("/forgot-password"):
      case location.pathname.includes("/recover-password"):
      case location.pathname.includes("/create-profile"):
      case location.pathname.includes("/404"):
        setIsShowMainLayout(false);
        break;
      default:
        setIsShowMainLayout(true);
    }
  }, [location.pathname]);

  return (
    <Auxiliary>
      {isShowMainLayout ? <Header /> : <HeaderWithBackButton />}
      <Routing />
      {isShowMainLayout ? <Footer /> : null}
    </Auxiliary>
  )
}
