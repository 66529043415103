// Server paths
// -------------------------------------------------------------------------------------
export const IDENTITY_API_URL_KEY = "https://identity.ardy.am";
export const API_URL_KEY = "https://api.ardy.am";
// -------------------------------------------------------------------------------------

// Local paths
// -------------------------------------------------------------------------------------
// export const IDENTITY_API_URL_KEY = "https://identity.ardy.am";
// export const API_URL_KEY = "https://93d7-87-241-138-217.ngrok-free.app";
// -------------------------------------------------------------------------------------

export const MINDALAY_REDIRECT_URL = "https://mindalay.com";
