import React, { useEffect, useState } from 'react'
import Parser from 'html-react-parser'
import { useSelector } from 'react-redux';
import TranslationService from '../../services/TranslationService';
import ApiService from '../../services/ApiService';
import AlertService from '../../services/AlertService';

export default function Section_8(props) {

  // Home

  const { currentPageContent, sectionClassName = "" } = props;
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const [isDisabledFields, setIsDisabledFields] = useState(false);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const [values, setValues] = useState({
    name: "",
    email: ""
  })

  const onChange = (event, name, maxLength = null,) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (!values.name.trim().length || !values.email.trim().length) {
      return false;
    }
    setIsDisabledFields(true);
    ApiService.createMemberApplication(values).then(response => {
      AlertService.alert("success", "Data saved");
      setIsDisabledFields(false);
      setValues({
        name: "",
        email: ""
      })
    }).catch(error => getFail(error))
  }

  const getFail = (error) => {
    error && AlertService.alert("error", error);
    setIsDisabledFields(false);
  }

  return currentPageContent && translationService ? <section className={`section position-relative ${sectionClassName}`} style={{ backgroundColor: currentPageContent.color }}>
    <div className="container-fluid">
        <div className='row'>
          <div className="col-md-6">
            <div className='ardy--title-wrapper ardy--text-white-wrapper'>
              {currentPageContent.title && Parser(currentPageContent.title)}
            </div>
            <div className='text-white mt-3'>
              {currentPageContent.description && Parser(currentPageContent.description)}
            </div>
          </div>
          <div className={`col-md-6`}>
            <div className="ardy--building-image-wrapper">
              <form className='ardy--paddingX-block' onSubmit={onSubmit}>
                <div className="mb-4">
                  <input
                    type="text"
                    className="form-control"
                    required={true}
                    placeholder={`${translationService.translate("TR_NAME_SURNAME")} *`}
                    value={values.name}
                    onChange={(event) => onChange(event, 'name')}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    className="form-control"
                    required={true}
                    placeholder={`${translationService.translate("TR_YOUR_EMAIL")} *`}
                    value={values.email}
                    onChange={(event) => onChange(event, 'email')}
                  />
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    type="submit"
                    className={`button primary--btn arrow--btn`}
                    disabled={isDisabledFields}
                    onClick={onSubmit}
                  >
                    {translationService.translate("TR_SUBSCRIBE")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </div>
  </section>
    : null
}
