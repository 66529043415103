import React, { useCallback, useEffect, useState } from "react";
import slash from "./../../assets/images/decoration/slash.svg";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import { addSpinner, removeSpinner } from "../../store/actions/spinner";
import TranslationService from "../../services/TranslationService";
import { v4 as uuidv4 } from "uuid";
import AlertService from "../../services/AlertService";
import SkeletonComponent from "../../components/Skeleton/SkeletonComponent";
import Parser from "html-react-parser";
import mainDecor from "./../../assets/images/decoration/main-decor.svg";

const Career = () => {
	const { language } = useSelector(state => state.language);
	const dispatch = useDispatch();
	const pageSize = 10;
	const { spinners } = useSelector(state => state.spinners);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [careers, setCareers] = useState([]);
	const [currentCareer, setCurrentCareer] = useState(null);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(1);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		setActivePageNumber(1);
		getCareer(1);
	}, [language]);

	const handlePageClick = () => {
		setActivePageNumber(activePageNumber + 1);
		getCareer(activePageNumber + 1, true);
	};

	const getCareer = (activePageNumber, add) => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getCareers(activePageNumber, pageSize)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				if (response && response.data) {
					if (add) {
						const data = [...careers, ...response.data];
						setCurrentCareer(data[0]);
						setCareers(data);
					} else {
						setCurrentCareer(response.data[0]);
						setCareers(response.data);
					}
				}
				extractSpinner(spinnerId);
				scrollToTop();
			})
			.catch(error => getFail(error, spinnerId));
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const getFail = (error, spinnerId) => {
		error && AlertService.alert("error", error);
		spinnerId && extractSpinner(spinnerId);
	};

	const setSpinner = useCallback(spinner => {
		dispatch(addSpinner(spinner));
	}, []);

	const extractSpinner = useCallback(spinner => {
		setTimeout(() => {
			dispatch(removeSpinner(spinner));
		}, 500);
	}, []);

	if (spinners && spinners.length) {
		return (
			<section className="section">
				<div className="container-fluid overflow-hidden p-0">
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent rows={1} titles={0} descs={0} />
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={400}
								titles={0}
								descs={0}
							/>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={700}
								titles={0}
								descs={0}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	return (
		!!translationService &&
		currentCareer &&
		careers && (
			<section className="position-relative">
				<img className="cover-decoration" src={mainDecor} alt="/" />
				<div className="container-fluid">
					<div className="text-center pb-5 max-w-700 m-auto">
						<h1 className="h1">
							<b>{translationService.translate("TR_CAREER_OPENINGS")}</b>
						</h1>
					</div>
					<div className="row justify-content-center">
						<div className="col-lg-8 ">
							<div
								className="ardy--course-card flex-wrap rounded shadow"
								style={{ backgroundColor: "#fff" }}>
								<div className="d-md-flex" style={{ padding: "16px 24px " }}>
									<div className="ardy--course-card-title mt-md-0 mt-2">
										{currentCareer.title && (
											<h2
												className="h2 me-5 fw-500"
												style={{ fontSize: "32px" }}
												title={currentCareer.title}>
												{currentCareer.title}
											</h2>
										)}
										<div className="d-flex  mt-4">
											{currentCareer.level &&
												currentCareer.level.key &&
												currentCareer.level.value && (
													<>
														<div className="">
															<span
																className="d-block max-line-1 fw-500"
																title={currentCareer.level.key}>
																{currentCareer.level.key}
															</span>
															<p
																className="mb-0 max-line-1 fw-500"
																title={currentCareer.level.value}>
																{currentCareer.level.value}
															</p>
														</div>
														<img
															className="mx-4 ardy--w-fit-content"
															src={slash}
															alt="slash"
														/>
													</>
												)}
											{currentCareer.schedule &&
												currentCareer.schedule.key &&
												currentCareer.schedule.value && (
													<div className="ardy--flex-1">
														<span
															className="d-block max-line-1 fw-500"
															title={currentCareer.schedule.key}>
															{currentCareer.schedule.key}
														</span>
														<p
															className="mb-0 max-line-1 fw-500"
															title={currentCareer.schedule.value}>
															{currentCareer.schedule.value}
														</p>
													</div>
												)}
										</div>
									</div>
									{currentCareer.applicationDeadline &&
										currentCareer.applicationDeadline.key &&
										currentCareer.applicationDeadline.value && (
											<div className="d-md-flex align-items-end ms-auto">
												<div className="fw-500">
													<span title={currentCareer.applicationDeadline.key}>
														{currentCareer.applicationDeadline.key}: &nbsp;
													</span>
													<span
														className="d-block"
														title={currentCareer.applicationDeadline.value}
														style={{
															fontSize: "16px",
															fontWeight: 450,
															lineHeight: "26px",
														}}>
														{currentCareer.applicationDeadline.value}
													</span>
												</div>
											</div>
										)}
								</div>
							</div>
						</div>
						{currentCareer.description && (
							<div className="col-lg-8">
								<div className="mt-5">{Parser(currentCareer.description)}</div>
							</div>
						)}
						{currentCareer.title && (
							<div className="col-lg-8">
								<div
									className="row text-center w-100"
									style={{ marginTop: "96px" }}>
									<h2
										className="h2 max-line-2 me-5"
										title={currentCareer.title}>
										<b>{currentCareer.title}</b>
									</h2>
									<div className="d-flex justify-content-center mt-1">
										<button
											type="button"
											style={{ padding: "8px 32px" }}
											className="link--btn primary--btn header--btn "
											// onClick={() => navigate(`/${language}${buttonData.buttonRedirectPage}`)}
											onClick={() =>
												window.open(currentCareer.applyLink, "_blank")
											}>
											{translationService.translate("TR_APPLY")}
										</button>
									</div>
								</div>
							</div>
						)}

						{careers && careers.length && (
							<div className="col-lg-8">
								<div className="row mt-5">
									<h2
										className="ardy--title-wrapper "
										style={{ fontSize: "24px" }}>
										<strong>
											{translationService.translate("TR_OTHER_JOB")}
										</strong>
									</h2>
									{careers.map((item, i) => (
										<div
											key={i}
											className={`col-12 ${
												currentCareer.id === item.id ? "d-none" : ""
											}`}>
											<div
												className="ardy--course-card flex-wrap align-items-center rounded-2 shadow mt-4 p-0"
												style={{
													backgroundColor: "#fff",
													padding: "16px 24px",
												}}>
												<div
													className="d-md-flex align-items-center"
													style={{ padding: "16px 24px" }}>
													<h2
														className="h2 max-line-2 mb-0 fw-500"
														style={{ fontSize: "32px" }}
														title={item.title}>
														{item.title}
													</h2>
													{/* <div className="ardy--course-card-title mt-md-0 mt-2">
                  </div> */}
													<div className="d-md-flex  ms-auto">
														<button
															style={{ padding: "8px 32px" }}
															className="button primary-outline--btn mt-md-0 px-5 mt-4 text-flex-xxl-nowrap"
															onClick={() => {
																setCurrentCareer(item);
																window.scrollTo({ top: 0, behavior: "smooth" });
															}}>
															{translationService.translate("TR_CHECK")}
														</button>
													</div>
												</div>
											</div>
										</div>
									))}
								</div>
							</div>
						)}
						{pagination && pagination.TotalCount === careers.length ? null : (
							<div className="d-flex justify-content-center mt-4">
								<p className="faq-more" onClick={handlePageClick}>
									{translationService.translate("TR_MORE")}
								</p>
							</div>
						)}
					</div>
				</div>
			</section>
		)
	);
};

export default Career;
