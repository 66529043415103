import React, { useEffect, useState, useId, useCallback } from "react";
import Auxiliary from "../../hoc/Auxiliary";
import arrowRightEmphasys from "./../../assets/icons/arrow/arrow-right-emphasys.svg";
import arrowRightS from "./../../assets/icons/arrow/arrow-right-s.svg";
import ApiService from "./../../services/ApiService";
import AlertService from "../../services/AlertService";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Section_1 from "../sections/Section_1";
import Section_2 from "../sections/Section_2";
import Section_3 from "../sections/Section_3";
import Section_4 from "../sections/Section_4";
import { v4 as uuidv4 } from "uuid";
import { addSpinner, removeSpinner } from "../../store/actions/spinner";
import TranslationService from "../../services/TranslationService";
import SkeletonComponent from "../../components/Skeleton/SkeletonComponent";
import Skeleton_Section_1 from "../skeletonsSections/Skeleton_Section_1";
import Skeleton_Section_2 from "../skeletonsSections/Skeleton_Section_2";
import Skeleton_Section_3 from "../skeletonsSections/Skeleton_Section_3";
import Skeleton_Section_4 from "../skeletonsSections/Skeleton_Section_4";
import Section_7 from "../sections/Section_7";
import Section_8 from "../sections/Section_8";
import useWindowSize from "../../components/hooks/usewindowSize";
import Parser from "html-react-parser";
import { motion } from "framer-motion";

import { ViewPosition } from "../../hoc/ViewPosition";

export default function Home() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [pageContent, setPageContent] = useState([]);
	const [freeCoursesByFaculties, setFreeCoursesByFaculties] = useState([]);
	const [coursesByTypes, setCoursesByTypes] = useState([]);
	const { language } = useSelector(state => state.language);
	const { translations } = useSelector(state => state.translation);
	const { spinners } = useSelector(state => state.spinners);
	const [translationService, setTranslationService] = useState(null);
	const windowSize = useWindowSize();

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (language) {
			getPageContentByName();
			getFreeCoursesByFaculties();
			getCoursesByTypes();
		}
		return () => {
			setPageContent([]);
			setFreeCoursesByFaculties([]);
			setCoursesByTypes([]);
		};
	}, [language]);

	const getPageContentByName = () => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getPageContentByName("home")
			.then(response => {
				if (response && response.data) {
					setPageContent(response.data);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFreeCoursesByFaculties = () => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getFreeCoursesByFaculties()
			.then(response => {
				if (response && response.data) {
					setFreeCoursesByFaculties(response.data);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getCoursesByTypes = () => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getCoursesByTypes()
			.then(response => {
				if (response && response.data) {
					setCoursesByTypes(response.data);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const setSpinner = useCallback(spinner => {
		dispatch(addSpinner(spinner));
	}, []);

	const extractSpinner = useCallback(spinner => {
		dispatch(removeSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error && AlertService.alert("error", error);
		spinnerId && extractSpinner(spinnerId);
	};

	if (spinners && spinners.length) {
		return (
			<>
				<Skeleton_Section_1 />
				<Skeleton_Section_2 />
				<Skeleton_Section_3 />
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<SkeletonComponent titleHeight={80} descs={0} images={0} />
						</div>
						<div className="col-md-4 mt-4">
							<SkeletonComponent titles={0} descs={0} imageHeight={300} />
						</div>
						<div className="col-md-4 mt-4">
							<SkeletonComponent titles={0} descs={0} imageHeight={300} />
						</div>
						<div className="col-md-4 mt-4">
							<SkeletonComponent titles={0} descs={0} imageHeight={300} />
						</div>
					</div>
				</div>
				<Skeleton_Section_4 />
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<SkeletonComponent titleHeight={80} descs={0} images={0} />
						</div>
						<div className="col-md-4 mt-4">
							<SkeletonComponent titles={0} descs={0} imageHeight={250} />
						</div>
						<div className="col-md-4 mt-4">
							<SkeletonComponent titles={0} descs={0} imageHeight={250} />
						</div>
						<div className="col-md-4 mt-4">
							<SkeletonComponent titles={0} descs={0} imageHeight={250} />
						</div>
					</div>
				</div>
			</>
		);
	}

	return translationService ? (
		<Auxiliary>
			{pageContent && pageContent.length
				? pageContent.map((pageContentItem, index) => {
						return (() => {
							switch (pageContentItem.sectionType) {
								case 1:
									if (
										pageContentItem.pageContents &&
										pageContentItem.pageContents.length
									) {
										let currentPageContent = pageContentItem.pageContents[0];
										return (
											<Section_1
												key={index}
												currentPageContent={currentPageContent}
												straightCols={true}
												isAnimation={true}
											/>
										);
									}
								case 2:
									if (
										pageContentItem.pageContents &&
										pageContentItem.pageContents.length
									) {
										let currentPageContent = pageContentItem.pageContents[0];
										return (
											<Section_2
												key={index}
												currentPageContent={currentPageContent}
												isAnimation={true}
											/>
										);
									}
								case 3:
									if (
										pageContentItem.pageContents &&
										pageContentItem.pageContents.length
									) {
										const pageContents = pageContentItem.pageContents;
										return (
											<Section_3
												key={index}
												pageContentItem={pageContentItem}
												pairedItems={pageContents.map(item => [item])}
											/>
										);
									}

								default:
									break;
							}
						})();
				  })
				: null}

			{coursesByTypes && coursesByTypes.length ? (
				<section className="section">
					<div className="container-fluid">
						<div className="row">
							<div className="col-12">
								<h2 className="h1 mb-4">
									{translationService.translate("TR_FEATURED")}{" "}
									<b className="h1-m">
										{translationService.translate("TR_COURSES")}
									</b>
								</h2>
							</div>
							{coursesByTypes.map((item, index) => {
								return (
									<div key={index} className="col-md-4 mt-4">
										<ViewPosition>
											{isVisible => (
												<motion.div
													initial={{ opacity: 0 }}
													animate={{
														opacity: isVisible ? 1 : 0,
													}}
													transition={{ duration: 0.5, delay: index / 3 }}
													className="ardy--course-topic-card ardy-course-image-card">
													<div className="ardy--course-card-title word-break-break-word max-line-1">
														{item.courseTypeInfo &&
															item.courseTypeInfo.title && (
																<h4>{item.courseTypeInfo.title}</h4>
															)}
													</div>

													<div
														className="cursor-pointer"
														onClick={() => {
															// navigate(
															// 	item.course
															// 		? `/${language}/course/${item.course.id}`
															// 		: `/${language}/courses`,
															// 	{ state: { ...item.courseTypeInfo } },
															// );
															navigate(`/${language}/courses`, {
																state: { ...item.courseTypeInfo },
															});
														}}>
														<div
															className="ardy--course-card-body"
															style={{
																backgroundImage: `url(${
																	item.courseTypeInfo &&
																	item.courseTypeInfo.imagePath
																		? item.courseTypeInfo.imagePath
																		: ""
																})`,
															}}>
															<div className="ardy--course-card-info">
																<div className="ardy--course-body-card-title">
																	<h5 className="word-break-break-word max-line-2">
																		{/* {item.course
																	? item.course.title && item.course.title
																		? item.course.title
																		: null
																	: item.courseTypeInfo
																	? item.courseTypeInfo.title
																		? item.courseTypeInfo.title
																		: ""
																	: null} */}
																		{item.courseTypeInfo &&
																		item.courseTypeInfo.title
																			? item.courseTypeInfo.title
																			: ""}
																	</h5>

																	<p className="word-break-break-word max-line-5">
																		{/* {item.course
																	? item.course.description &&
																	  item.course.description.value
																		? Parser(item.course.description.value)
																		: ""
																	: item.courseTypeInfo &&
																	  item.courseTypeInfo.description
																	? Parser(item.courseTypeInfo.description)
																	: null} */}
																		{item.courseTypeInfo &&
																		item.courseTypeInfo.description
																			? Parser(item.courseTypeInfo.description)
																			: ""}
																	</p>
																</div>
																<Link
																	to={`/${language}/courses`}
																	state={{ ...item.courseTypeInfo }}>
																	<div className="w-100 d-flex flex-column ">
																		<span className="d-flex justify-content-end">
																			<img
																				className="mt-3"
																				src={arrowRightS}
																				alt="arrow-right"
																			/>
																			<img
																				className="mt-3 ardy--arrow-hover"
																				src={arrowRightEmphasys}
																				alt="arrow-right"
																			/>
																		</span>
																	</div>
																</Link>
															</div>
														</div>
													</div>
												</motion.div>
											)}
										</ViewPosition>
									</div>
								);
							})}
						</div>
					</div>
				</section>
			) : null}
			{pageContent && pageContent.length
				? pageContent.map((pageContentItem, index) => {
						return (() => {
							switch (pageContentItem.sectionType) {
								case 4:
									if (
										pageContentItem.pageContents &&
										pageContentItem.pageContents.length
									) {
										return (
											<Section_4
												key={index}
												pageContentItem={pageContentItem}
											/>
										);
									}
								case 7:
									if (
										pageContentItem.pageContents &&
										pageContentItem.pageContents.length
									) {
										let currentPageContent = pageContentItem.pageContents[0];
										return (
											<Section_7
												key={index}
												currentPageContent={currentPageContent}
												sectionClassName="py-70"
											/>
										);
									}
								case 8:
									if (
										pageContentItem.pageContents &&
										pageContentItem.pageContents.length
									) {
										let currentPageContent = pageContentItem.pageContents[0];
										return (
											<Section_8
												key={index}
												currentPageContent={currentPageContent}
												sectionClassName="py-70"
											/>
										);
									}
								default:
									break;
							}
						})();
				  })
				: null}
			{/* {
      freeCoursesByFaculties && freeCoursesByFaculties.length ?
        <section className="section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h2 className="h1 mb-4">
                  {translationService.translate("TR_FREE")} <b className="h1-m">{translationService.translate("TR_OFFERS")}</b>
                </h2>
              </div>
              {
                freeCoursesByFaculties.map((item, index) => {
                  return <div key={index} className="col-md-4 mt-4">
                    <div className="ardy--course-topic-card ardy--offers">
                      <div className="ardy--course-card-title word-break-break-word max-line-1">
                        {
                          item.facultyInfo && item.facultyInfo.title && <h4>{item.facultyInfo.title}</h4>
                        }
                      </div>
                      <div className="ardy--course-card-body">
                        <div className="ardy--course-card-info">
                          <div className="ardy--course-body-card-title">
                            <h5 className='word-break-break-word max-line-2'>
                              {
                                item.courses && item.courses.length ?
                                  item.courses[0].title ? item.courses[0].title : ""
                                  : item.facultyInfo ?
                                    item.facultyInfo.title ? item.facultyInfo.title : ""
                                    : null
                              }
                            </h5>
                          </div>
                          <div className="w-100 d-flex flex-column ">
                            <p className="ardy--course-card-text word-break-break-word">
                              {
                                item.courses && item.courses.length ?
                                  item.courses[0].description &&
                                    item.courses[0].description &&
                                    item.courses[0].description.value ?
                                    item.courses[0].description.value :
                                    ""
                                  : item.facultyInfo ?
                                    item.facultyInfo.description ? item.facultyInfo.description : ""
                                    : null
                              }
                            </p>
                            <Link to={item.courses && item.courses.length && item.courses[0].id ? `/${language}/course/${item.courses[0].id}` : "#"}>
                              <img className="mt-3" src={arrowRightS} alt="arrow-right" />
                              <img className="mt-3 ardy--arrow-hover"
                                src={arrowRightEmphasys} alt="arrow-right" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                })
              }
            </div>
          </div>
        </section>
        : null
    } */}
		</Auxiliary>
	) : null;
}
