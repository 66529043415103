import { Col, Row } from 'react-bootstrap';
import "./../../sass/components/_loader.scss"

const SkeletonComponent = (
  { rows = 1, cols = 1, images = 1, imageHeight = 180, titles = 1, titleHeight = 40, descs = 3, descHeight = 20, maxWith = '100%', position = 'center' }
) => {
  const skeletonRows = Array.from({ length: rows }, (_, index) => index);
  const skeletonCols = Array.from({ length: cols }, (_, index) => index);
  const skeletonImages = Array.from({ length: images }, (_, index) => index);
  const skeletonTitles = Array.from({ length: titles }, (_, index) => index);
  const skeletonDescs = Array.from({ length: descs }, (_, index) => index);
  return (
    <>
      {
        skeletonRows?.map((_row, _rowIndex) => <Row key={_rowIndex} className='mx-0 w-100' style={{justifyContent: position, margin: '0 auto'}} >
          {skeletonCols?.map((_col, _colIndex) => (
            <Col key={_colIndex} style={{ maxWidth: maxWith}}>
              <div className="skeleton-block">
                {
                  skeletonImages?.map((_image, _imageIndex) =>
                    <div
                      key={_imageIndex}
                      className={`image ${skeletonImages.length > 1 ? "mb-2" : ""}`}
                      style={{ height: imageHeight + "px" }}
                    />
                  )
                }
                <div className="content">
                  {skeletonTitles?.map((_image, _imageIndex) => <h2 key={_imageIndex} style={{ height: titleHeight + "px" }} />)}
                  {skeletonDescs?.map((_image, _imageIndex) => <p key={_imageIndex} style={{ height: descHeight + "px" }} />)}
                </div>
              </div>
            </Col>
          ))}
        </Row>
        )
      }
    </>
  );
};

export default SkeletonComponent;


