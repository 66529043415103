import { toast } from 'react-toastify';
import SuccessSvg from "./../assets/icons/SuccessSvg";
import WarningSvg from "./../assets/icons/WarningSvg";

const CustomToastWithLink = (props) => {
  const { type, message } = props;

  const getTypeClass = () => {
    switch (type) {
      case "error":
        return "ardy--alert-error-line";
      case "success":
        return "ardy--alert-success-line";
      default:
        return "ardy--alert-error-line";
    }
  }

  return <div className='d-flex flex-column align-items-center gap-4 pb-3 py-4 ps-2 ardy--alert'>
    <div className={`ardy--alert-line ${getTypeClass(type)}`} />
    <div>
      {(() => {
        switch (type) {
          case "success":
            // return <img className='success-svg' src={successSvg} />
            return <SuccessSvg />
          case "error":
            // return <img className='warning-svg' src={warningSvg} />
            return <WarningSvg />
            default:
              // return <img className='warning-svg' src={warningSvg} />
              return <WarningSvg />
        }
      })()}
    </div>
    <p className='text-dark'>{message}</p>
    <button className='button primary--btn arrow--btn py-1'>Close</button>
  </div>
};

const getAlertType = (type, cb, message) => {
  if (!type) { cb.error(<CustomToastWithLink type="error" message={message} />); };
  cb(<CustomToastWithLink type={type} message={message} />);
}

export default class AlertService {

  static alert = (type, error) => {
    const respMessage = typeof error === "object" ? error.message || error.respmess : error;
    if (!respMessage) { return false; }
    getAlertType(type, toast, respMessage)
  }

  // static alertConfirm = (title, message, yes, no) => {
  //   return new Promise((resolve, reject) => {
  //     confirmAlert({
  //       customUI: ({ onClose }) => {
  //         return (
  //           <Modal centered className="confirm-modal" show={true}>
  //             <Modal.Header className="p-3">
  //               <Modal.Title>
  //                 <div className="d-flex align-items-center">
  //                   {/* <img src={warningSvg} alt="/" /> */}
  //                   <div className="d-block">
  //                     {
  //                       title ?
  //                         <span className="font-weight-bold">{title}</span>
  //                         : null
  //                     }
  //                   </div>
  //                 </div>
  //               </Modal.Title>
  //             </Modal.Header>
  //             {
  //               message ?
  //                 <Modal.Body className="p-3">
  //                   {
  //                     message ?
  //                       <span>{message}</span>
  //                       : null
  //                   }
  //                 </Modal.Body>
  //                 : null
  //             }
  //             <Modal.Footer className="p-3">
  //               <Button variant="primary" className='fl--button fl--button-default' onClick={() => { reject(); onClose(); }} >No</Button>
  //               <Button variant="outline-primary" className=' fl--button fl--button-outline' onClick={() => { resolve(); onClose(); }}>Yes</Button>
  //             </Modal.Footer>
  //           </Modal>
  //         );
  //       }
  //     })
  //   })
  // }

}