import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from "react-router-dom"
import closeMain from "./../../../assets/icons/closer/close-main.svg"
import eyeHide from "./../../../assets/icons/eye-hide.svg"
import eyeShow from "./../../../assets/icons/eye-show.svg"
import { useDispatch, useSelector } from 'react-redux'
import { setIsShowLoginModal } from '../../../store/actions/main'
import { IdentityService } from './../../../services/IdentityService';
import { MINDALAY_REDIRECT_URL } from './../../../constants/apiKeys';
import AlertService from '../../../services/AlertService'
import TranslationService from '../../../services/TranslationService'

const useOutsideAlerter = (ref, dispatch) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        dispatch(setIsShowLoginModal(false))
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export default function Login() {

  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { translations } = useSelector(state => state.translation);
  const { language } = useSelector(state => state.language)
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isDisabledButton, setIsDisabledButton] = useState(false);
  const [isShowInvalidFieldsDescription, setIsShowInvalidFieldsDescription] = useState(false);

  const [translationService, setTranslationService] = useState(null);
  useOutsideAlerter(wrapperRef, dispatch);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));

    return () => {
      dispatch(setIsShowLoginModal(false))
    }
  }, [translations]);

  const [values, setValues] = useState({
    username: "",
    password: "",
  })

  const onChange = (event, name, maxLength = null,) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
    setIsInvalidSubmit(false);
    setIsShowInvalidFieldsDescription(false);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    if (isDisabledButton || !values.username.trim().length || !values.password.trim().length || isShowInvalidFieldsDescription) { return false; }
    setIsDisabledButton(true)
    IdentityService.login(values).then(response => {
      setIsDisabledButton(false);
      if (response && response.data) {
        if (response.data.errorDescription === "invalid_username_or_password") {
          setIsShowInvalidFieldsDescription(true);
          return false;
        }
        if (response.data.refreshToken) {
          window.location.href = `${MINDALAY_REDIRECT_URL}/${language}/authentication/${response.data.refreshToken}`
        }
      }
    }).catch(error => getFail(error))
  }

  const getFail = (error) => {
    error && AlertService.alert("error", error);
    setIsDisabledButton(false);
  }

  return translationService ? <div className="ardy--login-container">
    <div className="ardy--login-wrapper" ref={wrapperRef}>
      <img
        className="ardy--login-close"
        src={closeMain}
        alt="close-main"
        onClick={() => dispatch(setIsShowLoginModal(false))}
      />
      <div className="ardy--login-body">
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <input
              type="text"
              className="form-control"
              placeholder={translationService.translate("TR_EMAIL")}
              value={values.username}
              onChange={(event) => onChange(event, "username")}
            />
          </div>
          <div className="mb-1 ardy--icon-input">
            {
              isShowPassword ?
                <img
                  src={eyeShow}
                  alt="eye-show"
                  onClick={() => setIsShowPassword(false)}
                />
                :
                <img
                  src={eyeHide}
                  alt="eye-hide"
                  onClick={() => setIsShowPassword(true)}
                />
            }
            <input
              type={`${isShowPassword ? "text" : "password"}`}
              className="form-control"
              placeholder={translationService.translate("TR_PASSWORD")}
              value={values.password}
              onChange={(event) => onChange(event, 'password')}
            />
          </div>
          {
            isShowInvalidFieldsDescription ?
              <small className='text-danger d-block mb-2'>{translationService.translate("TR_INVALID_EMAIL_OR_PASSWORD")}</small>
              : null
          }
          <div className="w-100 text-end mb-4">
            <Link to={`/${language}/forgot-password`} className="ardy--link-color text-end">{translationService.translate("TR_FORGOT_PASSWORD")}</Link>
          </div>
          <button
            type="submit"
            className={`button primary--btn  w-100 ${isDisabledButton ? "disabled--btn" : ""}`}
          >
            {translationService.translate("TR_SIGN_IN")}
          </button>
        </form>
        {/* <div className="ardy--login-footer">
          <p>{translationService.translate("TR_NEW_IN_ARDY")}</p>
          <button
            type="button"
            className="button primary-outline--btn"
            onClick={() => navigate(`/${language}/create-profile`)}
          >
            {translationService.translate("TR_SIGN_UP")}
          </button>
        </div> */}
      </div>
    </div>
  </div> : null
}
