import React, { useState, useEffect } from 'react'
import mainDecor from "./../../assets/images/decoration/main-decor.svg"
import Parser from 'html-react-parser'
import Carousel from "nuka-carousel"
import ShowMoreText from "react-show-more-text"
import TranslationService from '../../services/TranslationService'
import { useSelector } from 'react-redux'

export default function Section_5(props) {

  const { currentPageContent, isShowFullText, sectionClassName = "" } = props;
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService && currentPageContent ? <section className={`section position-relative ${sectionClassName}`}>
    <img className="cover-decoration" src={mainDecor} alt="/" />
    <div className="container">
      <div className="row justify-content-center">
        {
          currentPageContent.title ?
            <div className={`col-lg-8`}>
              <div className='ardy--title-wrapper text-center'>
                {
                  currentPageContent.title && Parser(currentPageContent.title)
                }
              </div>
            </div>
            : null
        }
        {
          currentPageContent.description ?
            <div className='col-lg-8 pb-5'>
              {/* {
                isShowFullText ?
                  <div className="mt-3 text-left">
                    {Parser(currentPageContent.description)}
                  </div>
                  : <ShowMoreText
                    lines={5}
                    more={translationService.translate("TR_MORE")}
                    less={translationService.translate("TR_LESS")}
                    expanded={false}
                    truncatedEndingComponent={<p className='d-block hidden-text'></p>}
                  >
                    <div className="mt-3 text-left">
                      {Parser(currentPageContent.description)}
                    </div>
                  </ShowMoreText>
              } */}
              <div className="mt-3 text-left">
                {Parser(currentPageContent.description)}
              </div>
            </div>
            : null
        }
      </div>
    </div>
    <div className="slider-wrapper">
      <div className="row align-items-center">
        <div className="col-md-12">
          <div id="carouselExampleRide" className="carousel slide " data-bs-ride="true">
            <div className="carousel-inner ">
              <div className='ardy--standart-carousel '>
                {
                  currentPageContent.imagePaths && currentPageContent.imagePaths.length ?
                    <Carousel
                      wrapAround={true}
                      // autoplay={true}
                      autoplayInterval={5000}
                      renderCenterLeftControls={({ previousSlide }) => (null)}
                      renderCenterRightControls={({ nextSlide }) => (null)}
                    >
                      {
                        currentPageContent.imagePaths.map((item, index) => {
                          return <div key={index} className="carousel-item active" style={{ backgroundImage: `url(${item})` }} >
                            {/* <img className="d-block w-100 h-100" src={item} alt="/" /> */}
                          </div>
                        })
                      }
                    </Carousel>
                    : null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> : null
}
