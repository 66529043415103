import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import mainDecor from "./../../assets/images/decoration/main-decor.svg";
import { useSelector } from "react-redux";
import arrowRightLight1 from "./../../assets/icons/arrow/arrow-right-light-l.svg";
import Parser from "html-react-parser";
import ReactPlayer from "react-player";
import poster from "./../../assets/images/poster.jpeg";
import PlaySvg from "../../assets/icons/PlaySvg";
import { useEffect } from "react";
import useWindowSize from "../../components/hooks/usewindowSize";

export default function Section_1(props) {
	const {
		currentPageContent,
		sectionClassName = "",
		isAnimation = true,
	} = props;
	const navigate = useNavigate();
	const { language } = useSelector(state => state.language);
	const { spinners } = useSelector(state => state.spinners);
	const windowSize = useWindowSize();
	let interval = 0;

	useEffect(() => {
		if (currentPageContent.videoLink || currentPageContent.videoPath) {
			videoRatio();
		}
	}, [currentPageContent, windowSize]);

	const videoRatio = () => {
		clearInterval(interval);
		const element = document.querySelector(".ardy--video-block");
		if (element) {
			element.style.height = (element?.offsetWidth * 9) / 16 + "px";
		} else {
			interval = setInterval(() => {
				videoRatio();
			}, 500);
		}
	};

	const getButtonType = buttonData => {
		if (!buttonData) {
			return false;
		}
		switch (buttonData.buttonType) {
			case 1:
				return (
					<button
						type="button"
						className="button primary--btn arrow--btn"
						// onClick={() => navigate(`/${language}${buttonData.buttonRedirectPage}`)}
						onClick={() => {
							if (buttonData.buttonRedirectPage) {
								window.open(buttonData.buttonRedirectPage, "_blank");
							}
						}}>
						{buttonData.buttonName}
					</button>
				);
			case 2:
				return (
					<button
						type="button"
						className="button primary-outline--btn"
						// onClick={() => navigate(`/${language}${buttonData.buttonRedirectPage}`)}
						onClick={() => {
							if (buttonData.buttonRedirectPage) {
								window.open(buttonData.buttonRedirectPage, "_blank");
							}
						}}>
						{buttonData.buttonName}
					</button>
				);
			case 3:
				return (
					<button
						type="button"
						className="button primary--btn arrow--btn"
						// onClick={() => navigate(`/${language}${buttonData.buttonRedirectPage}`)}
						onClick={() => {
							if (buttonData.buttonRedirectPage) {
								window.open(buttonData.buttonRedirectPage, "_blank");
							}
						}}>
						{buttonData.buttonName}
						<img src={arrowRightLight1} alt="/" />
					</button>
				);
			default:
				break;
		}
	};

	return currentPageContent ? (
		<section className={`section-top position-relative ${sectionClassName}`}>
			<img className="cover-decoration" src={mainDecor} alt="/" />
			<div>
				<div className="container-fluid">
					<div className="row align-items-center">
						<motion.div
							initial={{ y: 50 }}
							animate={{
								// opacity: isVisible && isAnimation ? 1 : 0,
								y: isAnimation && !spinners.length ? 0 : 50,
							}}
							viewport={{ once: true }}
							transition={{ duration: 0.5 }}
							className={`col-lg-6 ardy--padding-right-block `}>
							<div className="ardy--title-wrapper">
								{currentPageContent.title && Parser(currentPageContent.title)}
							</div>
							{currentPageContent.description &&
								Parser(currentPageContent.description)}
							{getButtonType(currentPageContent.button)}
						</motion.div>
						<motion.div
							initial={{ y: 300 }}
							animate={{
								// opacity: isVisible && isAnimation ? 1 : 0,
								y: !spinners.length && isAnimation ? 0 : 300,
							}}
							transition={{ duration: 0.5 }}
							className={`col-lg-6 order-lg-last order-first mb-5 mb-lg-0 pe-2 pe-lg-0`}>
							<div className="cover-ardy--section-image-wrapper ardy--video-block">
								{currentPageContent.videoLink ? (
									<ReactPlayer
										controls={true}
										className="ardy--custom-section-video-player ardy--primary-box-shadow w-auto h-100"
										url={currentPageContent.videoLink}
									/>
								) : currentPageContent.videoPath ? (
									<ReactPlayer
										controls={true}
										light={<img src={poster} alt="video" />}
										playIcon={
											<div className="ardy--custom-play-btn">
												<PlaySvg />
											</div>
										}
										className="ardy--custom-section-video-player ardy--primary-box-shadow"
										playing={true}
										url={currentPageContent.videoPath}
									/>
								) : currentPageContent.imagePaths &&
								  currentPageContent.imagePaths.length ? (
									<img src={currentPageContent.imagePaths[0]} alt="/" />
								) : null}
							</div>
						</motion.div>
					</div>
				</div>
			</div>
		</section>
	) : null;
}
