import {
  AUTH_SUCCESS,
  LOG_OUT
} from '../../constants/reduxKeys';

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  if (typeof JSON.parse(str) !== "object") {
    return false;
  }
  return true;
}

const initialState = {
  token: localStorage.getItem("token"),
  user: isJson(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")) : null,
};

const reducer = (state = initialState, action) => {

  switch (action.type) {

    case AUTH_SUCCESS:
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      localStorage.setItem("token", JSON.stringify(action.payload.token));
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,
        isLogin: true,
      };

    case LOG_OUT:
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      const navigate = action.payload;
      navigate && navigate("/");
      return {
        ...state,
        user: null,
        token: "",
      };
    default:
      break;
  }
  return state;
};

export default reducer;