import React, { useState, useEffect, useCallback } from "react";
import Auxiliary from "./../../hoc/Auxiliary";
import mainDecor from "./../../assets/images/decoration/main-decor.svg";
import slash from "./../../assets/images/decoration/slash.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../services/ApiService";
import Section_6 from "../sections/Section_6";
import AlertService from "../../services/AlertService";
import { addSpinner, removeSpinner } from "./../../store/actions/spinner";
import { v4 as uuidv4 } from "uuid";
import TranslationService from "../../services/TranslationService";
import SkeletonComponent from "../../components/Skeleton/SkeletonComponent";
import ContactUsForm from "./components/ContactUsForm";
import useWindowSize from "../../components/hooks/usewindowSize";

export default function Courses() {
	let { state } = useLocation();
	const windowSize = useWindowSize();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [selectedType, setSelectedType] = useState(null);
	const [courseTypes, setCourseTypes] = useState([]);
	const [faculties, setFaculties] = useState([]);
	const [pageContent, setPageContent] = useState([]);
	const [courseTypeInfo, setCourseTypeInfo] = useState(null);
	const [translationService, setTranslationService] = useState(null);
	const [showForm, setShowForm] = useState(false);
	const { spinners } = useSelector(state => state.spinners);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (language) {
			getCourseTypes(state);
			getPageContentByName();
		}
		return () => {
			setPageContent([]);
		};
	}, [language]);

	useEffect(() => {
		if (
			(state && state.showForm) ||
			(courseTypeInfo && courseTypeInfo.showApplicationForm)
		) {
			setShowForm(true);
		} else {
			setShowForm(false);
		}
	}, [state, courseTypeInfo]);

	useEffect(() => {
		if (selectedType) getCoursesByType(selectedType.id);
	}, [selectedType]);

	const getCourseTypes = item => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getCourseTypes()
			.then(response => {
				if (response && response.data && response.data.length) {
					setCourseTypes(response.data);
					setSelectedType(item ? item : response.data[0]);
					window.history.replaceState({}, document.title);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getCoursesByType = typeId => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getCoursesByType(typeId)
			.then(response => {
				if (response && response.data) {
					if (response.data.faculties) setFaculties(response.data.faculties);
					else setFaculties([]);
					if (response.data.courseTypeInfo)
						setCourseTypeInfo(response.data.courseTypeInfo);
					else setCourseTypeInfo(null);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getPageContentByName = () => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getPageContentByName("courses")
			.then(response => {
				if (response && response.data) {
					setPageContent(response.data);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const setSpinner = useCallback(spinner => {
		dispatch(addSpinner(spinner));
	}, []);

	const extractSpinner = useCallback(spinner => {
		setTimeout(() => {
			dispatch(removeSpinner(spinner));
		}, 500);
	}, []);

	const getFail = (error, spinnerId) => {
		error && AlertService.alert("error", error);
		spinnerId && extractSpinner(spinnerId);
		setSelectedType(null);
	};

	if (spinners && spinners.length) {
		return (
			<>
				<section className="mt-5 position-relative">
					<SkeletonComponent
						descs={0}
						images={0}
						titleHeight={70}
						maxWith="500px"
					/>
					<SkeletonComponent
						descs={0}
						images={0}
						titleHeight={80}
						maxWith="800px"
					/>
					<div className="max-w-700 m-auto">
						<SkeletonComponent
							rows={1}
							cols={1}
							titles={0}
							images={0}
							descs={6}
						/>
					</div>
					<div className="mt-5">
						<SkeletonComponent descs={0} titles={0} imageHeight={200} />
					</div>
					<div className="mt-5">
						<SkeletonComponent descs={0} titles={0} imageHeight={200} />
					</div>
					<div className="mt-5">
						<SkeletonComponent descs={0} titles={0} imageHeight={200} />
					</div>
				</section>
			</>
		);
	}

	const sortCardCourses = (faculties) => {
		let allCourses = []
		if (faculties && faculties.length) {
			faculties.forEach(faculty => {
				if (faculty && faculty.courses && faculty.courses.length) {
					faculty.courses.forEach(course => {
						let _course = { ...course };
						_course.facultyInfo = faculty.facultyInfo;
						allCourses.push(_course)
					})
				}
			});
		}

		if (allCourses && allCourses.length) {
			allCourses = allCourses.sort((a, b) => b.id - a.id);
			allCourses = allCourses.sort((a, b) => b.isActive - a.isActive);
			return allCourses.map((course, courseIndex) => {
				return (
					<div
						key={courseIndex}
						className="col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0"
						onClick={() => {
							navigate(`/${language}/course/${course.id}`);
						}}>
						<div className="ardy--card ardy--cursor-pointer h-100" style={{ opacity: course.isActive ? "1" : "0.4" }}>
							<div
								className="ardy--card-image ardy--image-background position-relative"
								style={{
									backgroundImage: `url(${course.imagePath})`,
									height: "250px",
								}}>
								<div
									style={{
										width: "56px",
										height: "56px",
										borderRadius: "50%",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
										backgroundColor: course.facultyInfo?.color,
										boxShadow:
											"0px 8px 20px 0px rgba(48, 72, 158, 0.40)",
										position: "absolute",
										top: "222px",
										right: "16px",
									}}>
									<img
										src={course.facultyInfo?.logoPath}
										alt="logo"
										style={{
											filter:
												course.facultyInfo?.color === "#ffffff" ||
													course.facultyInfo?.color === "#fff"
													? "invert(1)"
													: "",
										}}
									/>
								</div>
							</div>
							<div style={{ padding: "8px 16px" }}>
								<div className="row m-0">
									<div className="col-md-10">
										<h3 className="h3 mb-0 mt-1">
											{course.title}
										</h3>
									</div>
									<div className="col-12">
										{!course.isPaid ? (
											<p className="font-bold ardy--success-color fw-500 text-end mb-1">
												Free
											</p>
										) : (
											<p className="font-bold ardy--attention-color fw-500 text-end mb-1">
												{course.price.value?.regularPrice}
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			})
		}
	}

	return translationService ? (
		<Auxiliary>
			<section className="position-relative">
				<img className="cover-decoration" src={mainDecor} alt="main-decor" />
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div className="text-center py-5 max-w-700 m-auto">
								<h1 className="h1">
									{translationService.translate("TR_OUR")}{" "}
									<b className="h1-m">
										{translationService.translate("TR_OUR_COURSES")}
									</b>
								</h1>
								<div
									className="btn-group segmented--btn mt-5 d-flex justify-content-between flex-wrap pe-0"
									role="group"
									aria-label="Basic example">
									{courseTypes && courseTypes.length
										? courseTypes.map((item, index) => {
											return (
												<button
													key={index}
													type="button"
													title={item.title}
													className={`button text-nowrap max-line-1 ardy--choose-btn ${item.id === selectedType?.id ? "active" : ""
														}`}
													style={{
														width: Math.round(100 / courseTypes.length) + "%",
													}}
													onClick={() => {
														setSelectedType(item);
														if (item.showForm) {
															setShowForm(true);
														} else {
															setShowForm(false);
														}
													}}>
													{item.title}
												</button>
											);
										})
										: null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{pageContent && pageContent.length && faculties.length
				? pageContent.map((pageContentItem, index) => {
					return (() => {
						switch (pageContentItem.sectionType) {
							case 6:
								if (
									pageContentItem.pageContents &&
									pageContentItem.pageContents.length &&
									index % 2 === 0
								) {
									let currentPageContent = pageContentItem.pageContents[0];
									return (
										<div key={index} className="max-w-700 m-auto">
											<Section_6
												currentPageContent={currentPageContent}
												sectionClassName="py-0"
												isShowFullText={true}
											/>
										</div>
									);
								}
							default:
								break;
						}
					})();
				})
				: null}
			{faculties && faculties.length && selectedType ? (
				<section className="section">
					{!selectedType.useCardLayout ? (
						faculties.map((item, index) => {
							return (
								<div
									key={index}
									className={`ardy--course-list-container ${index ? "mt-8" : ""
										}`}>
									<div
										className="ardy--marketing-list-card"
										style={{
											backgroundColor: item.facultyInfo.color,
											height: "130px",
											marginRight: windowSize.width >= 768 ? "30%" : 0,
										}}>
										<div className="ardy--card-body d-flex align-items-center w-100 mw-100">
											<img
												style={{
													marginRight:
														windowSize.width >= 768 ? "10rem" : "1rem",
												}}
												className="mb-3"
												src={item.facultyInfo.logoPath}
												alt="logo"
											/>
											<div className="h-100 d-flex align-items-center">
												<h2 className="h1 text-white">
													{item.facultyInfo.title}
												</h2>
											</div>
										</div>
									</div>
									{item.courses && item.courses.length
										? item.courses.sort((a, b) => b.isActive - a.isActive).map((course, courseIndex) => {
											if (course.showOnHomePage) {
												return (
													<div
														key={courseIndex}
														className="ardy--course-card ardy--full-course-card flex-wrap">
														<div className="d-md-flex justify-content-between" style={{ opacity: course.isActive ? "1" : "0.4" }}>
															<div className="ardy--course-card-title mt-md-0 mt-3" style={{ width: "400px" }}>
																<h3
																	className="h3 max-line-3"
																	title={course.title}>
																	{course.title}
																</h3>
																{course.level ? (
																	<div className="d-flex fw-500">
																		<span
																			className="max-line-1"
																			title={course.level?.key}>
																			{course.level?.key}:&nbsp;
																		</span>
																		<span
																			className="max-line-1"
																			title={course.level.value}>
																			{" "}
																			{course.level.value}
																		</span>
																	</div>
																) : null}
															</div>
															<div>
																<div className="d-flex mt-md-0 mt-3 me-md-5">
																	{course.regular ? (
																		<div className="ardy--flex-1">
																			<span className="d-block max-line-3 fw-500">
																				{course.regular?.key}
																			</span>
																			<p className="mb-0 max-line-3 fw-500 text-nowrap">
																				{course.regular?.value}
																			</p>
																		</div>
																	) : null}
																	{
																		course.regular && course.intensive && course.regular.value && course.intensive.value ?
																			<img
																				className="mx-4 ardy--w-fit-content"
																				src={slash}
																				alt="slash"
																			/>
																			: null
																	}
																	{course.intensive ? (
																		<div className="ardy--flex-1">
																			<span className="d-block max-line-3 fw-500">
																				{course.intensive.key}
																			</span>
																			<p className="mb-0 max-line-3 fw-500 text-nowrap">
																				{course.intensive.value}
																			</p>
																		</div>
																	) : null}
																</div>
															</div>
															<div className="d-md-flex align-items-center">
																<Link
																	to={`/${language}/course/${course.id}`}
																	className="button primary-outline--btn mt-md-0 px-5 mt-4 text-nowrap">
																	{translationService.translate(
																		"TR_ABOUT_COURSE",
																	)}
																</Link>

															</div>
														</div>
													</div>
												);
											} else {
												return false
											}
										})
										: null}
								</div>
							);
						})
					) : (
						<div className="container-fluid">
							<div className="row align-self-stretch">
								{sortCardCourses(faculties)}
								{/* {faculties.map((item, index) => {
									return item.courses && item.courses.length
										? item.courses.map((course, courseIndex) => {
											if (course.showOnHomePage) {
												return (
													<div
														key={courseIndex}
														className="col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0"
														onClick={() => {
															navigate(`/${language}/course/${course.id}`);
														}}>
														<div className="ardy--card ardy--cursor-pointer h-100" style={{ opacity: course.isActive ? "1" : "0.4" }}>
															<div
																className="ardy--card-image ardy--image-background position-relative"
																style={{
																	backgroundImage: `url(${course.imagePath})`,
																	height: "250px",
																}}>
																<div
																	style={{
																		width: "56px",
																		height: "56px",
																		borderRadius: "50%",
																		display: "flex",
																		justifyContent: "center",
																		alignItems: "center",
																		backgroundColor: item.facultyInfo?.color,
																		boxShadow:
																			"0px 8px 20px 0px rgba(48, 72, 158, 0.40)",
																		position: "absolute",
																		// top: "137px",
																		top: "222px",
																		right: "16px",
																	}}>
																	<img
																		src={item.facultyInfo?.logoPath}
																		alt="logo"
																		style={{
																			filter:
																				item.facultyInfo?.color === "#ffffff" ||
																					item.facultyInfo?.color === "#fff"
																					? "invert(1)"
																					: "",
																		}}
																	/>
																</div>
															</div>
															<div style={{ padding: "8px 16px" }}>
																<div className="row m-0">
																	<div className="col-md-10">
																		<h3 className="h3 mb-0 mt-1">
																			{course.title}
																		</h3>
																	</div>
																	<div className="col-12">
																		{!course.isPaid ? (
																			<p className="font-bold ardy--success-color fw-500 text-end mb-1">
																				Free
																			</p>
																		) : (
																			<p className="font-bold ardy--attention-color fw-500 text-end mb-1">
																				{course.price.value?.regularPrice}
																			</p>
																		)}
																	</div>
																</div>
															</div>
														</div>
													</div>
												);
											} else {
												return false;
											}
										})
										: null;
								})} */}
							</div>
						</div>
					)}
				</section>
			) : courseTypeInfo ? (
				<section className="section pt-0">
					<div className="ardy--course-list-container ">
						<div className="container-fluid">
							<div className="row">
								<div className="col-12">
									<div className="text-center max-w-700 m-auto">
										<div
											className="text-start word-break-break-word text-center"
											style={{ lineHeight: "26px" }}>
											{courseTypeInfo.description}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			) : null}
			{pageContent && pageContent.length && faculties.length
				? pageContent.map((pageContentItem, index) => {
					return (() => {
						switch (pageContentItem.sectionType) {
							case 6:
								if (
									pageContentItem.pageContents &&
									pageContentItem.pageContents.length &&
									index % 2 !== 0
								) {
									let currentPageContent = pageContentItem.pageContents[0];
									return (
										<div key={index} className="max-w-700 m-auto">
											<Section_6
												currentPageContent={currentPageContent}
												isShowFullText={true}
											/>
										</div>
									);
								}
							default:
								break;
						}
					})();
				})
				: null}
			{showForm ? (
				<ContactUsForm translationService={translationService} />
			) : null}
		</Auxiliary>
	) : null;
}
