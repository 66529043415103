import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import arrowRightLight1 from "./../../assets/icons/arrow/arrow-right-light-l.svg"
import Parser from 'html-react-parser'
import Carousel from "nuka-carousel"
import ReactPlayer from 'react-player'
import poster from "./../../assets/images/poster.jpeg"
import PlaySvg from '../../assets/icons/PlaySvg'

export default function Section_4(props) {

  const { pageContentItem } = props;
  const navigate = useNavigate();
  const { language } = useSelector(state => state.language);

  const getButtonType = (buttonData) => {
    if (!buttonData) { return false; }
    switch (buttonData.buttonType) {
      case 1:
        return <button
          type="button"
          className="button primary--btn arrow--btn"
          // onClick={() => navigate(`/${language}${buttonData.buttonRedirectPage}`)}
          onClick={() => {
            if (buttonData.buttonRedirectPage) {
              window.open(buttonData.buttonRedirectPage, "_blank")
            }
          }}
        >
          {buttonData.buttonName}
        </button>
      case 2:
        return <button
          type="button"
          className="button primary-outline--btn"
          // onClick={() => navigate(`/${language}${buttonData.buttonRedirectPage}`)}
          onClick={() => {
            if (buttonData.buttonRedirectPage) {
              window.open(buttonData.buttonRedirectPage, "_blank")
            }
          }}
        >
          {buttonData.buttonName}
        </button>
      case 3:
        return <button
          type="button"
          className="button primary--btn arrow--btn"
          // onClick={() => navigate(`/${language}${buttonData.buttonRedirectPage}`)}
          onClick={() => {
            if (buttonData.buttonRedirectPage) {
              window.open(buttonData.buttonRedirectPage, "_blank")
            }
          }}
        >
          {buttonData.buttonName}
          <img src={arrowRightLight1} alt="/" />
        </button>
      default:
        break;
    }
  }

  return <section className="section position-relative">
    <div className="container-fluid">
      <div className="row">
        <div className='col-12'>
          <div className="carousel-inner">
            <div className='ardy--slider-section ardy--custom-carousel'>
              <Carousel
                wrapAround={true}
                // autoplay={true}
                autoplayInterval={5000}
                renderCenterLeftControls={({ previousSlide }) => (null)}
                renderCenterRightControls={({ nextSlide }) => (null)}


              // renderCenterLeftControls={({ previousSlide }) => (
              //   <button onClick={previousSlide}>
              //     <i className="fa fa-arrow-left" />
              //   </button>
              // )}
              // renderCenterRightControls={({ nextSlide }) => (
              //   <button onClick={nextSlide}>
              //     <i className="fa fa-arrow-right" />
              //   </button>
              // )}
              >
                {
                  pageContentItem.pageContents.map((currentPageContent, index) => {
                    return <div key={index} className="carousel-item active overflow-hidden">
                      <div className="row align-items-center">
                        <div className="col-md-5">
                          <div className='ardy--title-wrapper'>
                            {currentPageContent.title && Parser(currentPageContent.title)}
                          </div>
                          <span className='word-break-break-word'>
                            {currentPageContent.description && Parser(currentPageContent.description)}
                          </span>
                          {
                            getButtonType(currentPageContent.button)
                          }
                        </div>
                        <div className="col-md-7 order-md-last order-first mb-5 mb-md-0">
                          <div className="cover-ardy--section-image-wrapper">
                            {
                              currentPageContent.videoLink ?
                                <ReactPlayer
                                  controls={true}
                                  style={{ maxWidth: "100%" }}
                                  className="ardy--custom-section-video-player ardy--primary-box-shadow"
                                  url={currentPageContent.videoLink}
                                />
                                : currentPageContent.videoPath ?
                                  <ReactPlayer
                                    controls={true}
                                    light={<img src={poster} alt='video' />}
                                    style={{ maxWidth: "100%" }}
                                    playIcon={<div className='ardy--custom-play-btn'><PlaySvg /></div>}
                                    className="ardy--custom-section-video-player ardy--primary-box-shadow"
                                    playing={true}
                                    url={currentPageContent.videoPath}
                                  />
                                  : currentPageContent.imagePaths && currentPageContent.imagePaths.length ?
                                    <div className='d-flex justify-content-end'>
                                      <img style={{ maxHeight: "350px", maxWidth: "650px" }} src={currentPageContent.imagePaths[0]} alt="/" />
                                    </div>
                                    : null

                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  })
                }

              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}
