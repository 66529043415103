import { useEffect } from 'react'
import { setLanguages } from './store/actions/language'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ApiService from './services/ApiService'
import AlertService from './services/AlertService'

export default function Language() {

  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getLanguages();
  }, []);

  const getLanguages = () => {
    ApiService.getLanguages().then(response => {
      if (response && response.data) {
        dispatch(setLanguages([...response.data]));
      }
    }).catch(error => getFail(error))
  }

  const getFail = (error) => {
    error && AlertService.alert("error", error);
  };

  return null;
}
