import React, { useState, useEffect } from 'react'
import mainDecor from "./../../assets/images/decoration/main-decor.svg"
import eyeHide from "./../../assets/icons/eye-hide.svg"
import eyeShow from "./../../assets/icons/eye-show.svg"
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import TranslationService from './../../services/TranslationService';

export default function CreateProfile() {

  const { translations } = useSelector(state => state.translation);
  const { language } = useSelector(state => state.language);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isAgree, setIsAgree] = useState(false);

  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const [values, setValues] = useState({
    name: "",
    surname: "",
    phone: "",
    email: "",
    password: "",
  })

  const onChange = (event, name, maxLength = null,) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    console.log(values);
  }

  return translationService ? <section className="section-top position-relative">
    <img className="cover-profile-decoration" src={mainDecor} alt="/" />
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-4 col-md-6 m-auto">
          <h1 className="h2 mb-4">
            {translationService.translate("TR_CREATE_PROFILE")}
          </h1>
          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder={translationService.translate("TR_NAME")}
                value={values.name}
                onChange={(event) => onChange(event, 'name')}
              />
            </div>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder={translationService.translate("TR_SURNAME")}
                value={values.surname}
                onChange={(event) => onChange(event, 'surname')}
              />
            </div>
            <hr className="ardy--underline" />
            <div className="mb-4">
              <input
                type="tel"
                className="form-control"
                placeholder={translationService.translate("TR_PHONE_NUMBER")}
                value={values.phone}
                onChange={(event) => onChange(event, 'phone')}
              />
            </div>
            <div className="mb-4">
              <input
                type="email"
                className="form-control"
                placeholder={translationService.translate("TR_EMAIL")}
                value={values.email}
                onChange={(event) => onChange(event, 'email')}
              />
            </div>
            <div className="mb-4 ardy--icon-input">
              {
                isShowPassword ?
                  <img
                    src={eyeShow}
                    alt="eye-show"
                    onClick={() => setIsShowPassword(false)}
                  />
                  :
                  <img
                    src={eyeHide}
                    alt="eye-hide"
                    onClick={() => setIsShowPassword(true)}
                  />
              }
              <input
                type={`${isShowPassword ? "text" : "password"}`}
                className="form-control"
                placeholder={translationService.translate("TR_PASSWORD")}
                value={values.password}
                onChange={(event) => onChange(event, 'password')}
              />
            </div>
            <div className="form-check mb-4">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isAgree}
                id="isAgree"
                onChange={(event) => setIsAgree(event.target.checked)}
              />
              <label className="form-check-label cursor-pointer" htmlFor="isAgree">
                {
                  language === "am" ? <>
                    Ես համաձայն եմ <Link to={`/${language}/terms-and-conditions`} className="ardy--link-color">Պայմանների և Դրույթների</Link> հետ:
                  </> :
                    <>
                      I agree with <Link to={`/${language}/terms-and-conditions`} className="ardy--link-color">Terms and Conditions</Link>.
                    </>
                }
              </label>
            </div>
            <button
              type="submit"
              className={`button primary--btn arrow--btn w-100 ${!isAgree ? "disabled--btn" : ""}`}
            >
              {translationService.translate("TR_SIGN_UP")}
            </button>
          </form>
        </div>
      </div>
    </div>
  </section> : null
}
