import React, { useCallback, useEffect } from 'react'
import { setTranslations } from './store/actions/translation';
import ApiService from './services/ApiService';
import AlertService from './services/AlertService';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEFAULT_LANGUAGE_KEY, LANGUAGE_KEY } from './constants/mainKeys';
import { setLanguage } from './store/actions/language';

export default function Translation(props) {

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { language, languages } = useSelector(state => state.language);

  useEffect(() => {
    if (localStorage.getItem(LANGUAGE_KEY) === null || !localStorage.getItem(LANGUAGE_KEY)) {
      localStorage.setItem(LANGUAGE_KEY, DEFAULT_LANGUAGE_KEY);
    }
    if (localStorage.getItem(LANGUAGE_KEY) && location.pathname.split("/")[1] !== localStorage.getItem(LANGUAGE_KEY) && languages.length) {
      const isocode = location.pathname.split("/")[1];
      const isExist = languages.find(language => language.isocode2 === isocode);
      if (isExist) {
        localStorage.setItem(LANGUAGE_KEY, location.pathname.split("/")[1]);
        setLanguageHandler(location.pathname.split("/")[1]);
      } else {
        const oldIsoCode = location.pathname.split("/")[1];
        const newPath = location.pathname.toString().replace(oldIsoCode, language);
        setTimeout(() => {
          navigate(newPath);
        });
      }
    }
  }, [languages]);

  useEffect(() => {
    if (location.pathname.split("/")[1]){
      getAllTranslations(location.pathname.split("/")[1]);
    }
  }, [location.pathname.split("/")[1]]);

  const getAllTranslations = (lang) => {
    ApiService.getAllTranslations(lang).then(response => {
      if (response && response.data) {
        dispatch(setTranslations([...response.data]));
      }
    }).catch(error => getFail(error))
  }

  const setLanguageHandler = useCallback(language => {
    dispatch(setLanguage(language));
  }, []);

  const getFail = (error) => {
    error && AlertService.alert("error", error);
  };
  return <></>
}