import React from 'react'
import Parser from 'html-react-parser'
import Carousel from "nuka-carousel"
import mainDecor from "./../../assets/images/decoration/main-decor.svg"

export default function Section_3(props) {

  const { pairedItems, pageContentItem, sectionClassName="" } = props;

  return <section className={`section position-relative service-section ${sectionClassName}`}>
    <div className="service-decoration">
      <img src={mainDecor} alt="/" />
    </div>
    <div className="container-fluid">
      <div className="row d-md-none">
        <div className="col-12">

          <div className="carousel-inner">
            <div className='ardy--standart-carousel'>
              {
                pairedItems && pairedItems.length ?
                  <Carousel
                    wrapAround={true}
                    autoplay={true}
                    autoplayInterval={5000}
                    renderCenterLeftControls={({ previousSlide }) => (null)}
                    renderCenterRightControls={({ nextSlide }) => (null)}
                  >
                    {
                      pairedItems && pairedItems.map((item, idx) => {
                        if (item) {
                          return <div key={idx}>
                            {
                              item[0] ?
                                <div className="service-container mt-md-4">
                                  <div className="service-image">
                                    {
                                      item[0].imagePaths && item[0].imagePaths.length ?
                                        <img src={item[0].imagePaths[0]} alt="/" />
                                        : null
                                    }
                                  </div>
                                  <div className="service-info-wrapper">
                                    {item[0].title && Parser(item[0].title)}
                                    {item[0].description && Parser(item[0].description)}
                                  </div>
                                </div>
                                : null
                            }
                            {
                              item[1] ?
                                <div className="service-container mt-md-4">
                                  <div className="service-image">
                                    {
                                      item[1].imagePaths && item[1].imagePaths.length ?
                                        <img src={item[1].imagePaths[0]} alt="/" />
                                        : null
                                    }
                                  </div>
                                  <div className="service-info-wrapper">
                                    {item[1].title && Parser(item[1].title)}
                                    {item[1].description && Parser(item[1].description)}
                                  </div>
                                </div>
                                : null
                            }
                          </div>
                        } return false;

                      })
                    }
                  </Carousel>
                  : null
              }
            </div>
          </div>
        </div>
      </div>
      <div className="row d-none d-md-flex position-relative">
        {
          pageContentItem.pageContents.map((item, idx) => {
            return <div key={idx} style={{ marginTop: idx % 2 === 1 ? "72px" : "" }} className={`col-md-6`}>
              <div className={`service-container`}>
                <div className="service-image">
                  {
                    item.imagePaths && item.imagePaths.length ?
                      <img src={item.imagePaths[0]} alt="/" />
                      : null
                  }
                </div>
                <div className="service-info-wrapper">
                  {item.title && Parser(item.title)}
                  {item.description && Parser(item.description)}
                </div>
              </div>
            </div>
          })
        }
      </div>
    </div>
  </section>
}
