import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Courses from "./pages/courses/Courses";
import Events from "./pages/events/Events";
import Course from "./pages/courses/Course";
import { DEFAULT_LANGUAGE_KEY } from "./constants/mainKeys";
import ForgotPassword from "./pages/forgotPassword.js/ForgotPassword";
import TermsAndConditions from "./pages/termsAndConditions/TermsAndConditions";
import CreateProfile from "./pages/createProfile/CreateProfile";
import Page_404 from "./pages/page_404/Page_404";
import Contact from "./pages/contact/Contact";
import Specialization from "./pages/specialization/Specialization";
import FAQ from "./pages/faq/FAQ";
import Event from "./pages/events/Event";
import Career from "./pages/career/Career";
import News from "./pages/news/News";
import CurrentNews from "./pages/news/CurrentNews";
import ShortCourseMemberApplicationForm from "./pages/courses/components/ShortCourseMemberApplicationForm";

const Routing = () => {
	const { user } = useSelector(state => state.user);
	const { language } = useSelector(state => state.language);

	useEffect(() => {
		if (!language) {
			window.location.pathname = `/${DEFAULT_LANGUAGE_KEY}`;
		}
	}, [language]);

	return (
		<Routes element={<Home />}>
			<Route path="/" exact element={<Home />} />
			<Route path="/:language" exact element={<Home />} />
			<Route path="/:language/about" exact element={<About />} />
			<Route path="/:language/contact" exact element={<Contact />} />
			<Route path="/:language/courses" exact element={<Courses />} />
			<Route path="/:language/course/:courseId" exact element={<Course />} />
			<Route path="/:language/events" exact element={<Events />} />
			<Route path="/:language/event/:eventId" exact element={<Event />} />
			<Route
				path="/:language/course-registration/:courseId"
				exact
				element={<ShortCourseMemberApplicationForm />}
			/>
			<Route
				path="/:language/forgot-password"
				exact
				element={<ForgotPassword />}
			/>
			<Route
				path="/:language/terms-and-conditions"
				exact
				element={<TermsAndConditions />}
			/>
			<Route
				path="/:language/create-profile"
				exact
				element={<CreateProfile />}
			/>
			<Route
				path="/:language/specialization"
				exact
				element={<Specialization />}
			/>
			<Route path="/:language/news" exact element={<News />} />
			<Route path="/:language/news/:newsId" exact element={<CurrentNews />} />
			<Route path="/:language/career" exact element={<Career />} />
			<Route path="/:language/faq" exact element={<FAQ />} />
			<Route path="/:language/404" exact element={<Page_404 />} />
			<Route
				path="*"
				element={
					<Navigate to={`/${language ? language : DEFAULT_LANGUAGE_KEY}/404`} />
				}
			/>
		</Routes>
	);
};
export default Routing;
