import axios from "axios";
import AlertService from "./AlertService";
import { API_URL_KEY } from "../constants/apiKeys";
import {
	DEFAULT_LANGUAGE_KEY,
	LANGUAGE_KEY,
	TOKEN_KEY,
} from "../constants/mainKeys";

const api = axios.create({
	baseURL: API_URL_KEY,
	// withCredentials: true,
	headers: {
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "*",
	},
});

api.interceptors.request.use(async config => {
	config.headers = {
		Authorization: localStorage.getItem(TOKEN_KEY)
			? localStorage.getItem(TOKEN_KEY)
			: "",
		language: localStorage.getItem(LANGUAGE_KEY)
			? localStorage.getItem(LANGUAGE_KEY)
			: DEFAULT_LANGUAGE_KEY,
		"x-content-language": localStorage.getItem(LANGUAGE_KEY)
			? localStorage.getItem(LANGUAGE_KEY)
			: DEFAULT_LANGUAGE_KEY,
	};
	return config;
});

api.interceptors.response.use(
	response => {
		if (response && response.data && response.data?.status === 500) {
			AlertService.alert("error", "Something went wrong");
		}

		if (response && response.data && response.data.status !== 200) {
			const currentError = response.data;

			//if typeof error === string
			if (
				currentError &&
				currentError.message &&
				(!currentError.errors ||
					(currentError.errors && !currentError.errors.length))
			) {
				return Promise.reject(currentError);
			}

			//if typeof error === Array
			if (
				currentError.errors &&
				currentError.errors.length &&
				!Object.keys(currentError.errors).length
			) {
				currentError.errors.forEach(err => {
					if (err.length && err[0] && err[0].key) {
						return Promise.reject(err[0].key);
					}
				});
			}

			//if typeof error === Object
			if (currentError.errors && Object.keys(currentError.errors).length) {
				return Promise.reject(
					currentError.errors[Object.keys(currentError.errors)[0]][0]?.key,
				);
			}
		}

		if (response && response.data) {
			if (response.data.respmess === "User is not Authenticated") {
				localStorage.clear();
				window.location.href = `/`;
			}
			return !response.data.respcode
				? response.data
				: Promise.reject({
					respmess: response.data.respmess,
					respcode: response.data.respcode,
				});
		}
	},
	error => {
		if (error.response && error.response.status === 401) {
			return Promise.reject("401 (Unauthorized)");
		}
		if (error.response && error.response.status === 404) {
			return Promise.reject("404 Not Found");
		}
		if (error.response) {
			const currentError = error.response.data;
			if (currentError && currentError.message) {
				return Promise.reject(currentError);
			}
			//if typeof error === Array
			if (currentError.errors && currentError.errors.length) {
				currentError.errors.forEach(err => {
					if (err.length && err[0] && err[0].key) {
						AlertService.alert("error", err[0].key);
					}
				});
			}

			//if typeof error === Object
			if (
				typeof currentError.errors === "object" &&
				Object.keys(currentError.errors).length
			) {
				return Promise.reject(
					currentError.errors[Object.keys(currentError.errors)[0]][0]?.key,
				);
			}
		} else {
			return Promise.reject();
		}
	},
);

class ApiService {
	static userLogin(data) {
		return api.post(`/???`, data);
	}

	static userRegistration(data) {
		return api.post(`/???`, data);
	}

	static getAllTranslations(language) {
		return api.get(`/translation/GetAllByLang/${language}`);
	}

	static getLanguages() {
		return api.get(`/language/getall`);
	}

	static getCourseById(courseId) {
		return api.get(`/course/get/${courseId}`);
	}

	static getPageContentByName(pageName) {
		return api.get(`/pagecontent/getSectionsByName/${pageName}`);
	}

	static getFreeCoursesByFaculties() {
		return api.get(`/course/getFreeCoursesByFaculties`);
	}

	static getCoursesByTypes() {
		return api.get(`/course/getCoursesByTypes`);
	}

	static getCoursesByType(typeId) {
		return api.get(`/course/getCoursesByType/${typeId}`);
	}

	static getCourseTypes() {
		return api.get(`/coursetype/getall`);
	}

	static getFaq() {
		return api.get(`/information/GetFaq`);
	}
	static getCourseFaq() {
		return api.get(`/information/GetCourseFaq`);
	}
	static getBenefits(courseTypeId) {
		// return api.get(`/information/GetBenefits`);
		return api.get(`/Information/GetBenefitsByCourseType/${courseTypeId}`);
	}
	static getNews(pageNumber = 1, pageSize = 10) {
		return api.get(
			`/news/getall?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}
	static getNewsById(newsId) {
		return api.get(`/news/get/${newsId}`);
	}

	static getCareers(pageNumber, pageSize = 10) {
		return api.get(
			`/career/getall?pageNumber=${pageNumber}&pageSize=${pageSize}`,
		);
	}

	static createMemberApplication(data) {
		return api.post(`/Application/CreateMemberApplication`, data);
	}
	static createSpecialisationApplication(data) {
		return api.post(`/Application/CreateSpecialisationApplication`, data);
	}
	static createCorporateCourseApplication(data) {
		return api.post(`Application/createCorporateCourseApplication`, data);
	}
	static createCourseRegistrationApplication(data) {
		return api.post(`Application/CreateCourseRegistrationApplication`, data);
	}
}

export default ApiService;
