import React, { useCallback, useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import Auxiliary from '../../hoc/Auxiliary'
import ApiService from '../../services/ApiService'
import { useDispatch, useSelector } from 'react-redux'
import AlertService from '../../services/AlertService'
import TranslationService from '../../services/TranslationService'
import { addSpinner, removeSpinner } from '../../store/actions/spinner'
import { v4 as uuidv4 } from 'uuid'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import mainDecor from './../../assets/images/decoration/main-decor.svg'
import Parser from 'html-react-parser'

export default function FAQ() {
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.language);
  const { spinners } = useSelector(state => state.spinners);
  const { translations } = useSelector(state => state.translation);
  const [faq, setFaq] = useState(null);
  const [translationService, setTranslationService] = useState(null);
  const [pageSize, setPageSize] = useState(15);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getFaq();
    return () => {
      setFaq(null)
    }
  }, [language])


  const getFaq = () => {
    let spinnerId = uuidv4();
    setSpinner(spinnerId);
    ApiService.getFaq().then(response => {
      if (response && response.data) {
        setFaq(response.data);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const checkFaq = () => {
    if (faq && faq.value && faq.value.length && faq.value.find(item => item.question.trim().length && item.answer.trim().length)) return true;
    else return false;
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    setTimeout(() => {
      dispatch(removeSpinner(spinner));
    }, 500);
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert("error", error);
    spinnerId && extractSpinner(spinnerId);
  }

  if (spinners && spinners.length) {
    return <section className='section'>
      <div className='container-fluid overflow-hidden p-0'>
        <div className='row justify-content-center'>
          <div className='col-lg-8'>
            <SkeletonComponent rows={1} titles={0} imageHeight={120} descs={50} descHeight={70} />
          </div>
        </div>
      </div>
    </section>
  }

  return translationService ? <Auxiliary>

    {
      checkFaq() ?
        <section className="position-relative" style={{ paddingTop: "48px", paddingBottom: "48px" }}>

          <img className="cover-decoration" src={mainDecor} alt="main-decor" />
          <div className="container-fluid">
            <div className="ms-0">
              <div className="row ardy--faq-container justify-content-center">
                <div className="col-lg-8 text-center">
                  <h3 className="h2-m" style={{ marginBottom: "48px" }}>{faq?.key}</h3>
                </div>
                <div className="col-lg-8 mt-3 =">
                  <Accordion defaultActiveKey={['0']} alwaysOpen flush>
                    {
                      faq.value && faq.value.length ?
                        faq.value.map((item, index) => {
                          if (index < pageSize) {
                            return <Accordion.Item
                              key={index}
                              eventKey={index}
                              className={`
                              faq-accordion-item-transparent
                              ${index + 1 === pageSize ? "opacity-75" : ""}
                              `}
                            >
                              <Accordion.Header className='faq-accordion-header'>{item.question}</Accordion.Header>
                              <Accordion.Body className='ps-0'>
                                <span>
                                  {Parser(item.answer)}
                                </span>
                              </Accordion.Body>
                            </Accordion.Item>
                          }
                        })
                        : null
                    }
                  </Accordion>
                  {
                    faq.value && pageSize >= faq.value.length ? null :
                      <div className='d-flex justify-content-center mt-4'>
                        <p className='faq-more'
                          onClick={() => setPageSize(pageSize + 15)}
                        >
                          {translationService.translate("TR_MORE")}
                        </p>
                      </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </section>
        : null
    }
  </Auxiliary>
    : null
}




