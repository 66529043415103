import React, { useState, useEffect } from 'react'
import mainDecor from "./../../assets/images/decoration/main-decor.svg"
import TranslationService from '../../services/TranslationService';
import { useSelector } from 'react-redux';

export default function ForgotPassword() {

  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const [values, setValues] = useState({
    email: "",
  })

  const onChange = (event, name, maxLength = null,) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    setValues((prevState) => ({
      ...prevState,
      [name]: event.target.value,
    }));
    setIsInvalidSubmit(false);
  }

  const onSubmit = (event) => {
    event && event.preventDefault();
    console.log(values);
  }

  // return <Login />

  // return <RecoverPassword />

  return translationService ? <section className="section-top position-relative">
      <img className="cover-profile-decoration" src={mainDecor} alt="/" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-4 col-md-6 m-auto">
            <h1 className="h2 mb-4">
              {translationService.translate("TR_FORGOT_PASSWORD")}
            </h1>
          <p className="mt-4">{translationService.translate("TR_FORGOT_PASSWORD_INFO_TEXT")}</p>
            <hr className="ardy--underline" />
            <form onSubmit={onSubmit}>
              <div className="mb-4">
                <input
                  type="email"
                  className="form-control"
                  placeholder={translationService.translate("TR_EMAIL")}
                  value={values.email}
                  onChange={(event) => onChange(event, 'email')}
                />
              </div>
              <button type="submit" className="button primary--btn arrow--btn w-100">{translationService.translate("TR_SEND")}</button>
            </form>
          </div>
        </div>
      </div>
    </section> : null
}
