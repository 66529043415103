// import React, { useState, useEffect, useCallback } from 'react'
// import Auxiliary from '../../hoc/Auxiliary'
// import about1 from "./../../assets/images/about-1.png"
// import SkeletonComponent from '../../components/Skeleton/SkeletonComponent';
// import AlertService from '../../services/AlertService';
// import { addSpinner, removeSpinner } from '../../store/actions/spinner';
// import { useDispatch, useSelector } from 'react-redux';
// import TranslationService from '../../services/TranslationService';
// import { v4 as uuidv4 } from 'uuid'
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

// export default function Events() {

//   const [status, setStatus] = useState("event");
//   const dispatch = useDispatch();
//   const { translations } = useSelector(state => state.translation);
//   const { language } = useSelector(state => state.language);
//   const [translationService, setTranslationService] = useState(null);
//   const { spinners } = useSelector(state => state.spinners);
//   const [events, setEvents] = useState([]);
//   const navigate = useNavigate()


//   useEffect(() => {
//     setTranslationService(new TranslationService(translations));
//   }, [translations]);

//   useEffect(() => {
//     getEvents();
//   }, [status, language])

//   const scrollToTop = () => {
//     window.scrollTo({ top: 0, behavior :"smooth" });
//   }

//   const getEvents = () => {
//     let spinnerId = uuidv4();
//     setSpinner(spinnerId);
//     axios.get(`https://api.woodscenter.am/api/event?category=${status}`, {
//       headers: {
//         "X-Content-Language": language || "am"
//       }
//     }).then(response => {
//       if (response && response.data) {
//         setEvents(response.data);
//       }else{
//         setEvents([]);
//       }
//       extractSpinner(spinnerId);
//       scrollToTop()
//     }).catch(error => getFail(error, spinnerId))
//   }

//   const getDate = (date) => {
//     const eventDate = new Date(date);
//     const day = eventDate.getDate();
//     const month = eventDate.toLocaleString('default', { month: 'short' });
//     return (
//       <div className="ardy--vertical-date">
//         <span>{day}</span>
//         <span></span>
//         <span>{month}</span>
//       </div>
//     );
//   };

//   const setSpinner = useCallback(spinner => {
//     dispatch(addSpinner(spinner));
//   }, []);

//   const extractSpinner = useCallback(spinner => {
//     setTimeout(() => {
//       dispatch(removeSpinner(spinner));
//     }, 500);
//   }, []);

//   const getFail = (error, spinnerId) => {
//     error && AlertService.alert("error", error);
//     spinnerId && extractSpinner(spinnerId);
//   }

//   if (spinners && spinners.length) {
//     return <>
//       <section className="mt-5 position-relative">
//         <SkeletonComponent
//           descs={0}
//           images={0}
//           titleHeight={70}
//           maxWith='500px'
//         />
//         <SkeletonComponent
//           descs={0}
//           images={0}
//           titleHeight={80}
//           maxWith='800px'
//         />
//         <div className='container-fluid'>

//           <div className='row mt-5'>
//             <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
//               <SkeletonComponent
//                 descs={0}
//                 titles={0}
//                 imageHeight={300}
//               />
//             </div>
//             <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
//               <SkeletonComponent
//                 descs={0}
//                 titles={0}
//                 imageHeight={300}
//               />
//             </div>
//             <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
//               <SkeletonComponent
//                 descs={0}
//                 titles={0}
//                 imageHeight={300}
//               />
//             </div>
//             <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
//               <SkeletonComponent
//                 descs={0}
//                 titles={0}
//                 imageHeight={300}
//               />
//             </div>
//             <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
//               <SkeletonComponent
//                 descs={0}
//                 titles={0}
//                 imageHeight={300}
//               />
//             </div>
//             <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
//               <SkeletonComponent
//                 descs={0}
//                 titles={0}
//                 imageHeight={300}
//               />
//             </div>
//           </div>
//         </div>

//       </section>
//     </>
//   }


//   return (translationService ? <Auxiliary>
//     <section className="position-relative">
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-12">
//             <div className="text-center py-5 max-w-700 m-auto">
//               <h1 className="h1">{translationService.translate("TR_OUR")} <b className="h1-m">{translationService.translate("TR_EVENTS_Y")}</b></h1>
//               <div className="btn-group segmented--btn mt-5 d-flex justify-content-between flex-wrap pe-0 " role="group" aria-label="Basic example">
//                 <button
//                   type="button"
//                   title='Events'
//                   className={`button text-nowrap ardy--choose-btn ${status === "event" ? "active" : ""}`}
//                   style={{ width: Math.round(100 / 3) + "%" }}
//                   onClick={() => setStatus("event")}
//                 >
//                   {translationService.translate("TR_EVENTS")}
//                 </button>
//                 <button
//                   type="button"
//                   title='Master classes'
//                   className={`button text-nowrap ardy--choose-btn ${status === "masterclass" ? "active" : ""}`}
//                   style={{ width: Math.round(100 / 3) + "%" }}
//                   onClick={() => setStatus("masterclass")}
//                 >
//                   {translationService.translate("TR_MASTER_CLASSES")}
//                 </button>
//                 <button
//                   type="button"
//                   title='Conferances'
//                   className={`button text-nowrap ardy--choose-btn ${status === "conference" ? "active" : ""} `}
//                   style={{ width: Math.round(100 / 3) + "%" }}
//                   onClick={() => setStatus("conference")}
//                 >
//                   {translationService.translate("TR_CONFERENCES")}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//     <section className="section">
//       <div className="container-fluid">
//         <div className="row align-self-stretch">
//           {
//             events && events.events && events.events.length ?
//               events.events.map((item, index) => {
//                 return <div
//                   key={index}
//                   className="col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0"
//                   onClick={() => {
//                     navigate(`/${language}/event/${item._id}`)
//                     // window.open(`https://woodscenter.am/events/${item._id}`, "_blank")
//                   }}
//                 >
//                   <div className="ardy--card ardy--cursor-pointer h-100">
//                     <div className="ardy--card-image ardy--image-background" style={{ backgroundImage: `url(${item.thumbnail})` }}></div>
//                     <div className="ardy--card-body align-items-baseline  pb-0">
//                       {getDate(item.schedule)}
//                       <div className="ms-4">
//                         <h3 className="h3 mb-0 max-line-3" title={item.name}>{item.name}</h3>
//                         <p className="fw-500">{item.price ? item.price : translationService.translate("TR_FREE")}</p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               })
//               : null
//           }
//         </div>
//       </div>
//     </section>
//   </Auxiliary> : null
//   )
// }





import React, { useState, useEffect, useCallback } from 'react'
import Auxiliary from '../../hoc/Auxiliary'
import about1 from "./../../assets/images/about-1.png"
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent';
import AlertService from '../../services/AlertService';
import { addSpinner, removeSpinner } from '../../store/actions/spinner';
import { useDispatch, useSelector } from 'react-redux';
import TranslationService from '../../services/TranslationService';
import { v4 as uuidv4 } from 'uuid'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export default function Events() {

  const dispatch = useDispatch();
  const { translations } = useSelector(state => state.translation);
  const { language } = useSelector(state => state.language);
  const [translationService, setTranslationService] = useState(null);
  const { spinners } = useSelector(state => state.spinners);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate()


  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getEvents();
  }, [language])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  const getEvents = () => {
    let spinnerId = uuidv4();
    setSpinner(spinnerId);
    axios.get(`https://api.woodscenter.am/api/event`, {
      headers: {
        "X-Content-Language": language || "am"
      }
    }).then(response => {
      if (response && response.data && response.data.events && response.data.events.length) {
        const data = [...response.data.events];
        let sortedData = data.sort((a, b) => new Date(a.schedule) - new Date(b.schedule));
        setEvents(sortedData);
      } else {
        setEvents([]);
      }
      extractSpinner(spinnerId);
      scrollToTop()
    }).catch(error => getFail(error, spinnerId))
  }

  const getDate = (date) => {
    const eventDate = new Date(date);
    const day = eventDate.getDate();
    const month = eventDate.toLocaleString('default', { month: 'short' });
    return (
      <div className="ardy--vertical-date">
        <span>{day}</span>
        <span></span>
        <span>{month}</span>
      </div>
    );
  };

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    setTimeout(() => {
      dispatch(removeSpinner(spinner));
    }, 500);
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert("error", error);
    spinnerId && extractSpinner(spinnerId);
  }

  if (spinners && spinners.length) {
    return <>
      <section className="mt-5 position-relative">
        <SkeletonComponent
          descs={0}
          images={0}
          titleHeight={70}
          maxWith='500px'
        />

        <div className='container-fluid'>

          <div className='row mt-5'>
            <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
              <SkeletonComponent
                descs={0}
                titles={0}
                imageHeight={300}
              />
            </div>
            <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
              <SkeletonComponent
                descs={0}
                titles={0}
                imageHeight={300}
              />
            </div>
            <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
              <SkeletonComponent
                descs={0}
                titles={0}
                imageHeight={300}
              />
            </div>
            <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
              <SkeletonComponent
                descs={0}
                titles={0}
                imageHeight={300}
              />
            </div>
            <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
              <SkeletonComponent
                descs={0}
                titles={0}
                imageHeight={300}
              />
            </div>
            <div className='col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0'>
              <SkeletonComponent
                descs={0}
                titles={0}
                imageHeight={300}
              />
            </div>
          </div>
        </div>

      </section>
    </>
  }

  return (translationService ? <Auxiliary>
    <section className="position-relative">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="text-center py-5 max-w-700 m-auto">
              <h1 className="h1">{translationService.translate("TR_OUR")} <b className="h1-m">{translationService.translate("TR_EVENTS_Y")}</b></h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section pt-0">
      <div className="container-fluid">
        <div className="row align-self-stretch">
          {
            events && events.length ?
              events.map((item, index) => {
                return <div
                  key={index}
                  className="col-12 col-md-6 col-lg-4 mb-md-0 mb-3 mb-md-4 mb-0"
                  onClick={() => {
                    navigate(`/${language}/event/${item._id}`)
                    // window.open(`https://woodscenter.am/events/${item._id}`, "_blank")
                  }}
                >
                  <div className="ardy--card ardy--cursor-pointer h-100">
                    <div className="ardy--card-image ardy--image-background" style={{ backgroundImage: `url(${item.thumbnail})`, height:"250px" }}></div>
                    <div className="ardy--card-body align-items-baseline  pb-0">
                      {getDate(item.schedule)}
                      <div className="ms-4">
                        <h3 className="h3 mb-0 max-line-5" title={item.name}>{item.name}</h3>
                        <p className="fw-500">{item.display_price ? `${item.display_price} AMD` : translationService.translate("TR_FREE")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              })
              : null
          }
        </div>
      </div>
    </section>
  </Auxiliary> : null
  )
}
