import React from "react";
import Parser from "html-react-parser";
import { motion } from "framer-motion";
import { ViewPosition } from "../../hoc/ViewPosition";

export default function Section_2({ currentPageContent, isAnimation = true }) {
	return currentPageContent ? (
		<section
			className="section position-relative"
			style={{ backgroundColor: currentPageContent.color }}>
			<div className="container-fluid">
				<ViewPosition>
					{isVisible => (
						<div className="row">
							<motion.div
								className="col-md-5"
								initial={{ y: 100 }}
								animate={{
									// opacity: isVisible && isAnimation ? 1 : 0,
									y: isVisible && isAnimation ? 0 : 100,
								}}
								transition={{ duration: 0.5 }}>
								<div className="ardy--title-wrapper ardy--text-white-wrapper">
									{currentPageContent.title && Parser(currentPageContent.title)}
								</div>
								<div className="text-white mt-3">
									{currentPageContent.description &&
										Parser(currentPageContent.description)}
								</div>
							</motion.div>
							{/* <div className="col-md-5">
								<div className="ardy--title-wrapper ardy--text-white-wrapper">
									{currentPageContent.title && Parser(currentPageContent.title)}
								</div>
								<div className="text-white mt-3">
									{currentPageContent.description &&
										Parser(currentPageContent.description)}
								</div>
							</div> */}
							<div className={`col-md-7`}>
								<div className="ardy--building-image-wrapper">
									{currentPageContent.imagePaths &&
									currentPageContent.imagePaths.length ? (
										<motion.img
											className="w-100"
											src={currentPageContent.imagePaths[0]}
											alt="/"
											initial={{ y: 300 }}
											animate={{
												// opacity: isVisible && isAnimation ? 1 : 0,
												y: isVisible && isAnimation ? 0 : 300,
											}}
											transition={{ duration: 0.5 }}
										/>
									) : null}
								</div>
							</div>
							{/* <div className={`col-md-7`}>
								<div className="ardy--building-image-wrapper">
									{currentPageContent.imagePaths &&
									currentPageContent.imagePaths.length ? (
										<img
											className="w-100"
											src={currentPageContent.imagePaths[0]}
											alt="/"
										/>
									) : null}
								</div>
							</div> */}
						</div>
					)}
				</ViewPosition>
			</div>
		</section>
	) : null;
}
