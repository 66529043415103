import { ADD_SPINNER, REMOVE_SPINNER } from "../../constants/reduxKeys";

export function addSpinner(spinnerId) {
  return {
    type: ADD_SPINNER,
    payload: spinnerId
  };
};

export function removeSpinner(spinnerId) {
  return {
    type: REMOVE_SPINNER,
    payload: spinnerId
  };
};


