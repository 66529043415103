import React, { useCallback, useEffect, useState, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import Auxiliary from "../../hoc/Auxiliary";
import arrowLeft from "./../../assets/icons/arrow/arrow-left-main.svg";
import arrowRightLight1 from "./../../assets/icons/arrow/arrow-right-light-l.svg";
import bullet from "./../../assets/icons/_bullet.svg";
import ShowMoreText from "react-show-more-text";
import ApiService from "../../services/ApiService";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlertService from "../../services/AlertService";
import poster from "./../../assets/images/poster.jpeg";
import PlaySvg from "./../../assets/icons/PlaySvg";
import ReactPlayer from "react-player";
import TranslationService from "../../services/TranslationService";
import { addSpinner, removeSpinner } from "../../store/actions/spinner";
import { v4 as uuidv4 } from "uuid";
import Parser from "html-react-parser";
import SkeletonComponent from "../../components/Skeleton/SkeletonComponent";
import useWindowSize from "../../components/hooks/usewindowSize";
import Linkedin from "../../assets/icons/linkedin.png";

export default function Course() {
	const windowSize = useWindowSize();
	const { courseId } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { language } = useSelector(state => state.language);
	const { spinners } = useSelector(state => state.spinners);
	const [courseData, setCourseData] = useState(null);
	const [isRegular, setIsRegular] = useState(true);
	const [courseTypes, setCourseTypes] = useState([]);
	const { translations } = useSelector(state => state.translation);
	const [benefits, setBenefits] = useState(null);
	const [faq, setFaq] = useState(null);
	const [translationService, setTranslationService] = useState(null);
	let interval = 0;

	useEffect(() => {
		if (courseData && (courseData.videoLink || courseData.videoPath)) {
			videoRatio();
		}
	}, [windowSize, courseData]);

	const videoRatio = () => {
		clearInterval(interval);
		const element = document.querySelector(".ardy--video-block");
		if (element?.offsetWidth) {
			element.style.height = (element?.offsetWidth * 9) / 16 + "px";
		} else {
			interval = setInterval(() => {
				videoRatio();
			}, 500);
		}
	};

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (!courseId) {
			navigate(`/${language}/courses`);
		}
		getCourseTypes();
		getCourseById();
		getFaq();

		return () => {
			setCourseData(null);
			setCourseTypes([]);
			setFaq(null);
		};
	}, [language]);

	useEffect(() => {
		if (courseData && courseData.courseType) {
			getBenefits(courseData.courseType);
		}
		return () => {
			setBenefits(null);
		};
	}, [courseData]);

	const getCourseById = () => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getCourseById(courseId)
			.then(response => {
				if (response && response.data) {
					setCourseData(response.data);
				} else {
					navigate(`/${language}/courses`);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getCourseTypes = () => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getCourseTypes()
			.then(response => {
				if (response && response.data && response.data.length) {
					setCourseTypes(response.data);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFaq = () => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getCourseFaq()
			.then(response => {
				if (response && response.data) {
					setFaq(response.data);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getBenefits = courseType => {
		if (!courseType) {
			return false;
		}
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getBenefits(courseType)
			.then(response => {
				if (response && response.data) {
					setBenefits(response.data);
				}
				extractSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const divideString = (text, percentage) => {
		percentage = Math.min(100, Math.max(0, percentage));
		const firstPartLength = Math.floor(text.length * (percentage / 100));
		let splitIndex = text.lastIndexOf(" ", firstPartLength);
		if (splitIndex === -1) {
			splitIndex = firstPartLength;
		}
		const firstPart = text.substring(0, splitIndex);
		const secondPart = text.substring(splitIndex).trim();
		return [firstPart, secondPart];
	};

	const setSpinner = useCallback(spinner => {
		dispatch(addSpinner(spinner));
	}, []);

	const extractSpinner = useCallback(spinner => {
		setTimeout(() => {
			dispatch(removeSpinner(spinner));
		}, 500);
	}, []);

	const setAccordionClass = syllabusItem => {
		if (!syllabusItem) return "";
		if (syllabusItem && syllabusItem.weeks && syllabusItem.weeks.length) {
			let notEmptyItem = syllabusItem.weeks.find(item => item.weekName);
			if (notEmptyItem) return "";
			else return "empty-accordion";
		}
	};

	const checkSyllabus = () => {
		if (
			courseData &&
			courseData.syllabus &&
			courseData.syllabus.value &&
			courseData.syllabus.value.find(item => item.monthName.trim().length)
		)
			return true;
		else return false;
	};
	const checkFaq = () => {
		if (
			faq &&
			faq.value &&
			faq.value.length &&
			faq.value.find(
				item => item.question.trim().length && item.answer.trim().length,
			)
		)
			return true;
		else return false;
	};

	const getTitleFontSize = (title) => {
		if (!title) { return {} }
		if (windowSize.width > 991) {
			if (title.length < 30) {
				return { fontSize: "50px", lineHeight: "55px" }
			} else if (title.length < 60) {
				return { fontSize: "45px", lineHeight: "50px" }
			} else if (title.length < 70) {
				return { fontSize: "40px", lineHeight: "45px" }
			} else if (title.length < 80) {
				return { fontSize: "35px", lineHeight: "40px" }
			} else {
				return { fontSize: "30px", lineHeight: "35px" }
			}
		}
		return {}
	}

	const setClassName = (course) => {
		if (!course) { return "" };
		if (course.intensive && course.regular && course.intensive.value && course.regular.value) {
			return ""
		} else {
			return "without-checkbox"
		}
	}

	const getFail = (error, spinnerId) => {
		error && AlertService.alert("error", error);
		spinnerId && extractSpinner(spinnerId);
	};

	if (spinners && spinners.length) {
		return (
			<section className="section">
				<div className="container-fluid overflow-hidden p-0">
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={300}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={400}
								titles={0}
								descs={0}
							/>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={700}
								titles={0}
								descs={0}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}
	return translationService && courseData ? (
		<Auxiliary>
			<section className="section pb-0">
				<div className="container-fluid">
					{courseTypes && courseTypes.length ? (
						<Link className="mb-3 d-block" to={`/${language}/courses`}>
							<img src={arrowLeft} alt="arrow-left-main" />
							<b>{courseTypes[0].title}</b>
						</Link>
					) : null}
				</div>
				<div className="ardy--course-container">
					<div
						className="ardy--marketing-card"
						// --- //
						style={{
							backgroundColor: courseData.faculty.color
								? courseData.faculty.color
								: "",
						}}
					// --- //
					>
						<div
							className="ardy--card-body w-100"
							style={{
								backgroundColor: courseData.faculty.color
									? courseData.faculty.color
									: "",
							}}>
							{courseData.faculty ? (
								<img
									className="me-5"
									src={courseData.faculty.logoPath}
									alt="/"
								/>
							) : null}
							{/* <h2 className="h1 text-white" title={courseData.title} style={{ fontSize: getTitleFontSize(courseData.title) }}>
								{courseData.title}
							</h2> */}


							<h2 className="h1 text-white" title={courseData.title} style={getTitleFontSize(courseData.title)} >
								{courseData.title}
							</h2>


							{/* {
              courseData.description ?
                <p className="text-white mt-3">{Parser(courseData.description?.value)}</p>
                : null
            } */}
							{/* {
              courseData.description ?
                <ShowMoreText
                  lines={2}
                  more={translationService.translate("TR_MORE")}
                  less={translationService.translate("TR_LESS")}
                  className='text-white mt-4'
                  expanded={false}
                  anchorClass={"d-inline cursor-pointer"}
                >
                  <p className="text-white mt-3">{Parser(courseData.description?.value)}</p>
                </ShowMoreText>
                : null
            } */}
						</div>
						{/* {courseData.imagePath ? (
							<div
								className="ardy--marketing-image"
								style={{ backgroundImage: `url(${courseData.imagePath})` }}
							/>
						) : null} */}
						{courseData.imagePath ? (
							<div className="d-flex justify-content-center w-100">
								<img src={courseData.imagePath} style={{ height: "300px" }} className="course-lecturer-image" />
							</div>
						) : null}
					</div>
					<div className="ardy--course-card">
						<div className="d-lg-flex">
							<div className="ardy--course-card-title mt-md-0 mt-3">
								<div className="d-flex align-items-center">
									{courseData.duration ? (
										<div className="me-5">
											<span>{courseData.duration?.key}</span>
											<h3 className="h3">{courseData.duration?.value}</h3>
										</div>
									) : null}
									{courseData.level ? (
										<div className="me-5">
											<span>{courseData.level?.key}</span>
											<h3 className="h3">{courseData.level?.value}</h3>
										</div>
									) : null}
								</div>
							</div>
							<div className="ms-auto">
								{/* <div className="ardy--course-schedule-wrapper d-lg-flex mt-md-0 mt-3 me-md-5"> */}
								<div className="ardy--course-schedule-wrapper d-flex mt-md-0 mt-3 me-md-5">
									{courseData.regular &&
										courseData.regular?.key &&
										courseData.regular?.value ? (
										<div
											className={`ardy--flex-1 cursor-pointer ${isRegular
												? "ardy--course-reguliar"
												: "ardy--course-intensive"
												} ${setClassName(courseData)}`}
											onClick={() => setIsRegular(true)}>
											<b className="d-block">{courseData.regular?.key}</b>
											<p className={`mb-0 text-nowrap`}>
												{courseData.regular?.value}
											</p>
										</div>
									) : null}
									{courseData.intensive &&
										courseData.intensive?.key &&
										courseData.intensive?.value ? (
										<div
											className={`ardy--flex-1 cursor-pointer ${!isRegular
												? "ardy--course-reguliar"
												: "ardy--course-intensive"
												} ${setClassName(courseData)}`}
											onClick={() => setIsRegular(false)}>
											<b className="d-block">{courseData.intensive?.key}</b>
											<p className="mb-0 text-nowrap">
												{courseData.intensive?.value}
											</p>
										</div>
									) : null}
								</div>
							</div>
							<div className="d-lg-flex align-items-center ms-auto">
								<div className="ardy--course-schedule-wrapper d-flex mt-md-0 mt-3 me-md-5">
									{courseData.price ? (
										<div className="text-md-end">
											<span className="d-block">{courseData.price?.key}</span>
											{courseData.price.value &&
												courseData.price.value.regularPrice &&
												isRegular ? (
												<b className="mb-0 h2-m">
													{courseData.price.value.regularPrice}
												</b>
											) : null}
											{courseData.price.value &&
												courseData.price.value.intensivePrice &&
												!isRegular ? (
												<b className="mb-0 h2-m">
													{courseData.price.value.intensivePrice}
												</b>
											) : null}
										</div>
									) : null}
								</div>
								<button
									type="button"
									className={`button primary--btn arrow--btn mt-lg-0 mt-2 ${!courseData.isActive ? "disabled-btn" : ""}`}
									onClick={() => {
										if (!courseData.isActive) {
											return false;
										}
										if (courseData?.showRegistrationForm) {
											navigate(
												`/${language}/course-registration/${courseData.id}`,
											);
										} else if (courseData.registationLink) {
											window.open(courseData.registationLink, "_blank");
										}
									}}>
									{
										courseData.isActive ? translationService.translate("TR_REGISTER_FOR_COURSE") : translationService.translate("TR_INACTIVE_COURSE")
									}
									<img src={arrowRightLight1} alt="arrow-right-l" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="section pt-0">
				<div className="container-fluid position-relative">
					<div className="row align-items-start">
						<div
							className="col-lg-8 ardy--paddingX-block"
							style={{ paddingTop: "5rem" }}>
							<div className="row">
								<div className="col-12">
									<div
										className="ardy--designed-video ardy--video-block"
										style={{ maxWidth: "100%" }}>
										{courseData.videoLink ? (
											<ReactPlayer
												controls={true}
												className="ardy--custom-section-video-player w-auto h-100"
												url={courseData.videoLink}
											/>
										) : courseData.videoPath ? (
											<ReactPlayer
												controls={true}
												light={
													<img
														className="ardy--primary-box-shadow"
														src={poster}
														alt="video"
													/>
												}
												playIcon={
													<div className="ardy--custom-play-btn">
														<PlaySvg />
													</div>
												}
												className="ardy--custom-section-video-player h-100 w-100"
												playing={true}
												url={courseData.videoPath}
											/>
										) : courseData.imagePaths &&
											courseData.imagePaths.length ? (
											<img src={courseData.imagePaths[0]} alt="/" />
										) : null}
									</div>
								</div>
							</div>
							{courseData.description && courseData.description.value ? (
								<div
									className={`row ${courseData.videoLink || courseData.videoPath ? "mt-5" : ""
										} `}>
									<div className="col-12">
										<h2 className="h2-m">{courseData.description?.key}</h2>
										{/* <ShowMoreText
                      lines={5}
                      more={translationService.translate("TR_MORE")}
                      less={translationService.translate("TR_LESS")}
                      className="mb-3 mt-0"
                      expanded={false}
                      truncatedEndingComponent={<p className='d-block hidden-text'></p>}
                    >
                      <span className="mt-3 word-break-break-word">
                        {Parser(courseData.description?.value)}
                      </span>
                    </ShowMoreText> */}
										<span className="mt-3 word-break-break-word">
											{Parser(courseData.description?.value)}
										</span>
									</div>
								</div>
							) : null}
							{courseData.infoSection1 && courseData.infoSection1.value ? (
								<div className="row mt-5">
									<div className="col-12">
										<h2 className="h2-m">{courseData.infoSection1?.key}</h2>
										{/* <ShowMoreText
                      lines={5}
                      more={translationService.translate("TR_MORE")}
                      less={translationService.translate("TR_LESS")}
                      className="mb-3 mt-0"
                      expanded={false}
                      truncatedEndingComponent={<p className='d-block hidden-text'></p>}
                    >
                      <span className="mt-3 word-break-break-word">
                        {Parser(courseData.infoSection1?.value)}
                      </span>
                    </ShowMoreText> */}
										<span className="mt-3 word-break-break-word">
											{Parser(courseData.infoSection1?.value)}
										</span>
									</div>
								</div>
							) : null}
							{courseData.infoSection2 && courseData.infoSection2.value ? (
								<div className="row mt-5">
									<div className="col-12">
										<h2 className="h2-m">{courseData.infoSection2?.key}</h2>
										{/* <ShowMoreText
                      lines={5}
                      more={translationService.translate("TR_MORE")}
                      less={translationService.translate("TR_LESS")}
                      className="mb-3 mt-0"
                      expanded={false}
                      truncatedEndingComponent={<p className='d-block hidden-text'></p>}
                    >
                      <span className="mt-3 word-break-break-word">
                        {Parser(courseData.infoSection2?.value)}
                      </span>
                    </ShowMoreText> */}
										<span className="mt-3 word-break-break-word">
											{Parser(courseData.infoSection2?.value)}
										</span>
									</div>
								</div>
							) : null}
						</div>
						{benefits &&
							benefits.value &&
							benefits.value.length &&
							benefits.value.find(item => item.trim().length) ? (
							<div className="col-lg-4 benefits-block">
								<div className="row">
									<div className="col-12">
										<h2 className="h2-m">{benefits?.key}</h2>
										<ul className="ardy--aside-list">
											{benefits.value.map((item, index) => {
												return (
													<li key={index}>
														<img src={bullet} alt="/" />
														<span className="word-break-break-word">
															{item}
														</span>
													</li>
												);
											})}
										</ul>
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			</section>

			<section className="section pt-0">
				<div className="container-fluid">

					<div className="row">
						<div className="col-lg-8 ardy--paddingX-block">
							{
								courseData.certificatePath ? (
									<div className="ardy--designed-image">
										<img src={courseData.certificatePath} alt="certificate" />
									</div>
								) : null
							}
						</div>
					</div>
				</div>
			</section>



			{checkSyllabus() ? (
				<section
					className="ardy--gray-bkg-lvl-3 ardy--side-section"
					style={{ paddingTop: "48px", paddingBottom: "48px" }}>
					<div className="ms-0">
						<div className="row">
							<div className="col-lg-8 text-center ardy--padding-right-block">
								<h3 className="h2-m">{courseData.syllabus?.key}</h3>
							</div>
							<div className="col-lg-8 mt-3 ardy--padding-right-block">
								<Accordion defaultActiveKey={["0"]} alwaysOpen flush>
									{courseData.syllabus.value.map((syllabusItem, monthIndex) => {
										return (
											<Accordion.Item
												key={`month_${monthIndex}`}
												eventKey={`month_${monthIndex}`}
												className={`syllabus-accordion-item ${setAccordionClass(
													syllabusItem,
												)}`}>
												<Accordion.Header>
													{syllabusItem.monthName}
												</Accordion.Header>
												<Accordion.Body>
													{syllabusItem.weeks && syllabusItem.weeks.length
														? syllabusItem.weeks.map(
															(syllabusWeek, weekIndex) => {
																if (
																	syllabusWeek.subjects &&
																	syllabusWeek.subjects.length &&
																	syllabusWeek.subjects.find(item => item)
																) {
																	return (
																		<Accordion.Item
																			className="p-0 ms-0 ms-sm-5 faq-accordion-item-transparent "
																			key={`month_${monthIndex}_week_${weekIndex}`}
																			eventKey={`month_${monthIndex}_week_${weekIndex}`}>
																			<Accordion.Header>
																				{syllabusWeek.weekName}
																			</Accordion.Header>
																			<Accordion.Body>
																				{syllabusWeek.subjects &&
																					syllabusWeek.subjects.length
																					? syllabusWeek.subjects.map(
																						(
																							syllabusWeekSubject,
																							subjectIndex,
																						) => (
																							<span
																								key={`month_${monthIndex}_week_${weekIndex}_subject_${subjectIndex}`}
																								className="d-block my-3 ps-3">
																								{syllabusWeekSubject}
																							</span>
																						),
																					)
																					: null}
																			</Accordion.Body>
																		</Accordion.Item>
																	);
																} else {
																	return (
																		<span className="d-block my-3 accordion-body-list fw-500">
																			{syllabusWeek.weekName}
																		</span>
																	);
																}
															},
														)
														: null}
												</Accordion.Body>
											</Accordion.Item>
										);
									})}
								</Accordion>
							</div>
						</div>
					</div>
				</section>
			) : null}

			<section className={`section ${!checkSyllabus() ? "pt-0" : ""}`}>
				<div className="container-fluid">
					{/* <div className="row">
						<div className="col-lg-8 ardy--paddingX-block">
							<div className="row">
								{courseData.infoSection3 && courseData.infoSection3.value ? (
									<div className="col-12">
										<h2 className="h2-m">{courseData.infoSection3?.key}</h2>
										{divideString(courseData.infoSection3?.value).length
											? divideString(courseData.infoSection3?.value, 70).map(
												(item, index) => {
													return (
														<div key={index}>
															<p className="mt-3">{item}</p>
															{!index && courseData.certificatePath ? (
																<div className="ardy--designed-image">
																	<img
																		src={courseData.certificatePath}
																		alt="certificate"
																	/>
																</div>
															) : null}
														</div>
													);
												},
											)
											: null}
									</div>
								) : courseData.certificatePath ? (
									<div className="ardy--designed-image">
										<img src={courseData.certificatePath} alt="certificate" />
									</div>
								) : null}
							</div>
						</div>
					</div> */}
					<div className="row">
						<div className="col-lg-8 ardy--paddingX-block">
							<div className="row">
								{courseData.infoSection3 && courseData.infoSection3.value ? (
									<div className="row mt-5">
										<div className="col-12">
											<h2 className="h2-m">{courseData.infoSection3?.key}</h2>
											<span className="mt-3 word-break-break-word">
												{Parser(courseData.infoSection3?.value)}
											</span>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
					{courseData.lecturers &&
						courseData.lecturers.value &&
						courseData.lecturers.value.length ? (
						<div className="row">
							<div className="col-lg-8 ardy--paddingX-block">
								<div className="row">
									<div className="col-12">
										<h2 className="h2-m my-5">{translationService.translate(courseData.lecturers?.key)}</h2>
									</div>
									{courseData.lecturers.value.map((item, index) => {
										return (
											<div key={index} className="col-12">
												<div className="d-md-flex">
													{item.imagePath ? (
														<img
															className="ardy--user-avatar-xl me-5"
															src={item.imagePath}
															alt="avatar"
														/>
													) : (
														""
													)}
													<div className="">
														<h4 className="h2 word-break-break-word mt-md-0 mt-2 d-flex align-items-center gap-3">
															{item.fullName}
															{item?.linkedinLink ? (
																<a href={item.linkedinLink} target="_blank">
																	<img
																		style={{
																			borderRadius: "50%",
																			width: "27px",
																			height: "27px",
																		}}
																		src={Linkedin}
																		alt="Linkedin"
																	/>
																</a>
															) : (
																""
															)}
														</h4>
														<b
															className="word-break-break-word"
															style={{ fontSize: "24px" }}>
															{item.position}
														</b>
														<p className="mt-3 word-break-break-word">
															{item.bio}
														</p>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					) : null}
					{courseData.guests &&
						courseData.guests.value &&
						courseData.guests.value.length ? (
						<div className="row">
							<div className="col-lg-8 ardy--paddingX-block">
								<div className="row">
									<div className="col-12">
										<h2 className="h2-m my-5">{translationService.translate(courseData.guests?.key)}</h2>
									</div>
									{courseData.guests.value.map((item, index) => {
										return (
											<div key={index} className="col-12">
												<div className="d-md-flex">
													{item.imagePath ? (
														<img
															className="ardy--user-avatar-xl me-5"
															src={item.imagePath}
															alt="avatar"
														/>
													) : (
														""
													)}
													<div className="">
														<h4 className="h2 word-break-break-word mt-md-0 mt-2 d-flex align-items-center gap-3">
															{item.fullName}
															{item?.linkedinLink ? (
																<a href={item.linkedinLink} target="_blank">
																	<img
																		style={{
																			borderRadius: "50%",
																			width: "27px",
																			height: "27px",
																		}}
																		src={Linkedin}
																		alt="Linkedin"
																	/>
																</a>
															) : (
																""
															)}
														</h4>
														<b
															className="word-break-break-word"
															style={{ fontSize: "24px" }}>
															{item.position}
														</b>
														<p className="mt-3 word-break-break-word">
															{item.bio}
														</p>
													</div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					) : null}
				</div>
			</section>

			{checkFaq() ? (
				<section
					className="ardy--gray-bkg-lvl-3 ardy--side-section "
					style={{ paddingTop: "48px", paddingBottom: "48px" }}>
					<div className="ms-0">
						<div className="row ardy--faq-container">
							<div className="col-lg-8 text-center ardy--padding-right-block">
								<h3 className="h2-m" style={{ marginBottom: "27px" }}>
									{faq?.key}
								</h3>
							</div>
							<div className="col-lg-8 mt-3 ardy--padding-right-block">
								<Accordion defaultActiveKey={["0"]} alwaysOpen flush>
									{faq.value && faq.value.length
										? faq.value.map((item, index) => {
											return (
												<Accordion.Item
													key={index}
													eventKey={index}
													className="faq-accordion-item-transparent">
													<Accordion.Header className="faq-accordion-header">
														{item.question}
													</Accordion.Header>
													<Accordion.Body className="ps-0">
														<span>{Parser(item.answer)}</span>
													</Accordion.Body>
												</Accordion.Item>
											);
										})
										: null}
								</Accordion>
							</div>
						</div>
					</div>
				</section>
			) : null}
		</Auxiliary>
	) : null;
}
