import React, { useState, useEffect } from 'react'
import Parser from 'html-react-parser'
import ShowMoreText from "react-show-more-text"
import TranslationService from '../../services/TranslationService'
import { useSelector } from 'react-redux'

export default function Section_6(props) {

  const { currentPageContent, sectionClassName = "", isShowFullText } = props;
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService && currentPageContent ?
    <section className={`section ${sectionClassName}`}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className='ardy--title-wrapper'>
              {
                currentPageContent.title && Parser(currentPageContent.title)
              }
            </div>
            <div className="mt-3">
              {/* {
                currentPageContent.description ?
                  isShowFullText ?
                    <div className="mt-3">
                      {Parser(currentPageContent.description)}
                    </div>
                    : <ShowMoreText
                      lines={5}
                      more={translationService.translate("TR_MORE")}
                      less={translationService.translate("TR_LESS")}
                      expanded={false}
                      truncatedEndingComponent={<p className='d-block hidden-text'></p>}
                    >
                      <div className="mt-3">
                        {Parser(currentPageContent.description)}
                      </div>
                    </ShowMoreText>
                  : null

              } */}
              <div className="mt-3">
                {Parser(currentPageContent.description)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> : null
}
