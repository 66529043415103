import React from 'react'

const SuccessSvg = ({ className = "success-svg" }) => {
  return (
    <svg className={className} width="49" height="56" viewBox="0 0 49 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path id="Polygon 8" d="M46.4978 14.2733C47.118 14.6279 47.5 15.2834 47.5 15.9927V40.0073C47.5 40.7166 47.118 41.3721 46.4978 41.7267L25.5022 53.734C24.882 54.0887 24.118 54.0887 23.4978 53.734L2.50219 41.7267C1.88203 41.3721 1.5 40.7166 1.5 40.0073V32.6621C1.5 31.1907 3.0353 30.223 4.36276 30.8578L21.8855 39.2363C22.8038 39.6754 23.9053 39.3548 24.4445 38.4915L46.4978 3.18177M1.5 19.5617V15.9927C1.5 15.2834 1.88203 14.6279 2.50219 14.2733L23.4978 2.266C24.118 1.91133 24.882 1.91133 25.5022 2.266L36 8.26964" stroke="#1E2329" strokeWidth="3" />
    </svg>

  )
}
export default SuccessSvg;