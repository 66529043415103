import React, { useCallback, useEffect, useRef, useState } from "react";
import arrowLeft from "../../assets/icons/arrow/arrow-left-main.svg";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import arrowRightWhite from "../../assets/icons/arrow/arrow-right-white.svg";
import arrowLeftWhite from "../../assets/icons/arrow/arrow-left-white.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 as uuidv4 } from "uuid";
import Parser from "html-react-parser";
import mainDecor from "./../../assets/images/decoration/main-decor.svg";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import moment from "moment";
import TranslationService from "../../services/TranslationService";
import SkeletonComponent from "../../components/Skeleton/SkeletonComponent";
import { addSpinner, removeSpinner } from "../../store/actions/spinner";
import ApiService from "../../services/ApiService";
import AlertService from "../../services/AlertService";

export default function New() {
	const { language } = useSelector(state => state.language);
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [ref, setRef] = useState();
	const dispatch = useDispatch();
	const { newsId } = useParams();
	const { spinners } = useSelector(state => state.spinners);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [currentNews, setCurrentNews] = useState(null);
	const [news, setNews] = useState(null);
	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);
	useEffect(() => {
		getNews();
	}, [language, newsId]);
	const getNews = () => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getNewsById(newsId)
			.then(response => {
				if (response && response.data) {
					const data = response.data;
					setCurrentNews(data);
				}
				extractSpinner(spinnerId);
				scrollToTop();
			})
			.catch(error => getFail(error, spinnerId));
		ApiService.getNews(1, 4)
			.then(response => {
				if (response && response.data) {
					const data = response.data;
					setNews(data);
				}
				extractSpinner(spinnerId);
				scrollToTop();
			})
			.catch(error => getFail(error, spinnerId));
	};
	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};
	const getFail = (error, spinnerId) => {
		error && AlertService.alert("error", error);
		spinnerId && extractSpinner(spinnerId);
	};

	const setSpinner = useCallback(spinner => {
		dispatch(addSpinner(spinner));
	}, []);

	const extractSpinner = useCallback(spinner => {
		setTimeout(() => {
			dispatch(removeSpinner(spinner));
		}, 500);
	}, []);
	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
	if (spinners && spinners.length) {
		return (
			<section className="section">
				<div className="container-fluid overflow-hidden p-0">
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={300}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={400}
								titles={0}
								descs={0}
							/>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={700}
								titles={0}
								descs={0}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	return (
		translationService &&
		currentNews && (
			<section className="section pb-0 position-relative">
				<img className="cover-decoration" src={mainDecor} alt="/"></img>
				<div className="container-fluid">
					<div className="row justify-content-center">
						<div className="col-12">
							<Link className="mb-3 d-block" to={`/${language}/news`}>
								<img src={arrowLeft} alt="arrow-left-main" />
								<b>{translationService.translate("TR_NEWS")}</b>
							</Link>
						</div>
						<div className="col-lg-10">
							{translationService &&
								currentNews &&
								currentNews.imagePaths &&
								!!currentNews.imagePaths.length && (
									<div>
										<div className="position-relative">
											<Swiper
												onSwiper={setRef}
												spaceBetween={10}
												navigation={false}
												loop={true}
												thumbs={{
													swiper:
														thumbsSwiper && !thumbsSwiper.destroyed
															? thumbsSwiper
															: null,
												}}
												modules={[FreeMode, Navigation, Thumbs]}
												className="ardy_carusel">
												{translationService &&
													currentNews &&
													currentNews.imagePaths &&
													!!currentNews.imagePaths.length &&
													currentNews.imagePaths.map((el, i) => (
														<SwiperSlide key={i}>
															<img
																className=" shadow-2 ardy-news-image rounded-4"
																src={el}
															/>
														</SwiperSlide>
													))}
												<div className="carusel-footer d-flex d-md-none">
													<div>
														<button
															onClick={() => {
																ref?.slidePrev();
															}}>
															<img src={arrowLeftWhite} />
														</button>
														<button
															onClick={() => {
																ref?.slideNext();
															}}>
															<img src={arrowRightWhite} />
														</button>
													</div>
												</div>
											</Swiper>
										</div>

										<div className="col-12 d-none d-md-block">
											<Swiper
												onSwiper={setThumbsSwiper}
												slidesPerView={12}
												loop={true}
												freeMode={true}
												watchSlidesProgress={true}
												modules={[FreeMode, Navigation, Thumbs]}
												className="ardy-news-carusel"
												breakpoints={{}}>
												{currentNews.imagePaths.map((el, i) => (
													<SwiperSlide key={i}>
														<div
															style={{
																background: `url(${el}), no-repeat`,
																backgroundSize: "cover",
															}}
															key={i}
															className={`ardy-ardy-news-carusel-img rounded p-0`}
														/>
													</SwiperSlide>
												))}
											</Swiper>
										</div>
									</div>
								)}
						</div>
					</div>
					<div className="row align-items-center justify-content-center">
						<div className="col-10 my-5">
							{currentNews && !!currentNews.createDate && (
								<div className="time mb-5">
									<span>
										{capitalizeFirstLetter(
											moment(currentNews.createDate).locale(language).fromNow(),
										)}
									</span>
								</div>
							)}
							{currentNews && currentNews.title && (
								<h1 className="h1" style={{ lineHeight: "60px" }}>
									{currentNews.title}
								</h1>
							)}
						</div>
						{currentNews && currentNews.description && (
							<div className="col-10 mb-5">
								{Parser(currentNews.description)}
							</div>
						)}
					</div>

					{news && !!news.length && (
						<div className="col-12">
							<h2 className="ardy--title-wrapper">
								<strong>{translationService.translate("TR_RECENTS")}</strong>
							</h2>
							<div className="row align-self-stretch">
								{news.map((item, i) => (
									<div
										className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-3 mb-0"
										key={i}>
										<Link className="" to={`/${language}/news/${item.id}`}>
											<div className="ardy--card ardy--cursor-pointer h-100">
												<div
													className="ardy--card-image ardy--image-background"
													style={{
														backgroundImage: `url(${item.imagePaths[0]})`,
													}}></div>
												<div className="ardy--card-body align-items-baseline  d-block">
													<div className="time">
														<span className="first_letter_uppercase d-block mb-2 mt-1">
															{capitalizeFirstLetter(
																moment(item.createDate)
																	.locale(language)
																	.fromNow(),
															)}
														</span>
													</div>
													{/* {getDate(item.schedule)} */}
													<h3
														className="h3 mb-0 max-line-3 fw-400"
														style={{ lineHeight: "30px" }}
														title={item.title}>
														{item.title}
													</h3>
												</div>
											</div>
										</Link>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			</section>
		)
	);
}
