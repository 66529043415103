import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

export default function PageHelmet() {
  const { language } = useSelector((state) => state.language);
  const [title, setTitle] = useState("ARDY");

  useEffect(() => {
    if (language) {
      switch (language) {
        case "en":
          setTitle("ARDY");
          break;
        case "am":
          setTitle("ԱՐԴԻ");
          break;
        default:
          setTitle("ARDY");
          break;
      }
    }
  }, [language]);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
}
