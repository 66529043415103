import React, { useEffect, useState } from 'react'
import arrowLeftMain from "./../../assets/icons/arrow/arrow-left-main.svg"
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import TranslationService from '../../services/TranslationService';

export default function HeaderWithBackButton() {

  const { pathname } = useLocation();
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  return translationService ? <header>
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <span
            className="align-items-center d-flex fw-bold ardy--cursor-pointer"
            style={{ fontSize: "18px" }}
            onClick={() => {
              if (pathname.includes("404")) {
                window.history.go(-2);
              } else {
                window.history.back();
              }
            }}
          >
            <img src={arrowLeftMain} alt="arrow-left-main"
              className="d-inline-block align-text-top" />
            {translationService.translate("TR_BACK")}
          </span>
        </div>
      </nav>
    </header> : null
}
