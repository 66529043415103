import React from 'react'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'

export default function Skeleton_Section_4() {

  return <section className="section position-relative">
    <div className="container-fluid">
      <div className="row">
        <div className='col-12'>
          <div className="carousel-inner">
            <div className=''>
              <div className="carousel-item active overflow-hidden">
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <div className='ardy--title-wrapper'>
                      <SkeletonComponent
                        images={0}
                        descs={3}
                        titleHeight={150}
                      />
                      <SkeletonComponent
                        images={0}
                        descs={0}
                        titleHeight={50}
                        maxWith='50%'
                        position='left'
                      />
                    </div>
                  </div>
                  <div className="col-md-7 order-md-last order-first mb-5 mb-md-0">
                    <div className="cover-ardy--section-image-wrapper d-flex justify-content-end" style={{ height: "400px", minHeight: "400px" }}>
                      <SkeletonComponent
                        titles={0}
                        descs={0}
                        imageHeight={400}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
}
