
import React, { useCallback, useEffect, useState } from 'react'
import Auxiliary from '../../hoc/Auxiliary'
import ApiService from "./../../services/ApiService"
import { useDispatch, useSelector } from 'react-redux'
import AlertService from '../../services/AlertService'
import { addSpinner, removeSpinner } from '../../store/actions/spinner'
import { v4 as uuidv4 } from 'uuid'
import Section_1 from '../sections/Section_1'
import Section_2 from '../sections/Section_2'
import Section_3 from '../sections/Section_3'
import Section_4 from '../sections/Section_4'
import Section_5 from '../sections/Section_5'
import Section_6 from '../sections/Section_6'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import Skeleton_Section_6 from '../skeletonsSections/Skeleton_Section_6'

export default function About() {

  const dispatch = useDispatch();
  const { language } = useSelector(state => state.language);
  const { spinners } = useSelector(state => state.spinners);
  const [pageContent, setPageContent] = useState([]);

  useEffect(() => {
    if (language) {
      getPageContentByName();
    }
    return () => {
      setPageContent([]);
    }
  }, [language])

  const getPageContentByName = () => {
    let spinnerId = uuidv4();
    setSpinner(spinnerId);
    ApiService.getPageContentByName("about").then(response => {
      if (response && response.data) {
        setPageContent(response.data);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    setTimeout(() => {
      dispatch(removeSpinner(spinner));
    }, 500);
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert("error", error);
    spinnerId && extractSpinner(spinnerId);
  }

  if (spinners && spinners.length) {
    return <>
      <section className="mt-5 position-relative">
          <SkeletonComponent rows={1} cols={1} images={0} titleHeight={100} descs={0} maxWith='500px' />
        <div className='max-w-700 m-auto'>
          <SkeletonComponent rows={1} cols={1} titles={0} images={0} descs={6} />
        </div>
        <SkeletonComponent
          descs={0}
          titles={0}
          imageHeight={400}
        />
        <div className='max-w-700 m-auto mt-5'>
          <SkeletonComponent rows={1} cols={1} titleHeight={80} images={0} descs={0} position='left' maxWith='400px' />
          <SkeletonComponent rows={1} cols={1} titles={0} images={0} descs={6} />
        </div>
        <div className='max-w-700 m-auto mt-5'>
          <SkeletonComponent rows={1} cols={1} titleHeight={80} images={0} descs={0} position='left' maxWith='400px' />
          <SkeletonComponent rows={1} cols={1} titles={0} images={0} descs={6} />
        </div>
        <div className='max-w-700 m-auto mt-5'>
          <SkeletonComponent rows={1} cols={1} titleHeight={80} images={0} descs={0} position='left' maxWith='400px' />
          <SkeletonComponent rows={1} cols={1} titles={0} images={0} descs={6} />
        </div>
      </section>
    </>
  }

  return (
    <Auxiliary>
      {
        pageContent && pageContent.length ?
          pageContent.map((pageContentItem, index) => {
            return (() => {
              switch (pageContentItem.sectionType) {
                case 1:
                  if (pageContentItem.pageContents && pageContentItem.pageContents.length) {
                    let currentPageContent = pageContentItem.pageContents[0];
                    return <Section_1 key={index} currentPageContent={currentPageContent} />
                  }
                case 2:
                  if (pageContentItem.pageContents && pageContentItem.pageContents.length) {
                    let currentPageContent = pageContentItem.pageContents[0];
                    return <Section_2 key={index} currentPageContent={currentPageContent} />
                  }
                case 3:
                  if (pageContentItem.pageContents && pageContentItem.pageContents.length) {
                    const pageContents = pageContentItem.pageContents;
                    const pairedItems = [];
                    for (let i = 0; i < pageContents.length; i += 2) {
                      const pair = [pageContents[i], pageContents[i + 1]];
                      pairedItems.push(pair);
                    }
                    return <Section_3 key={index} pageContentItem={pageContentItem} pairedItems={pairedItems} />
                  }
                //
                case 4:
                  if (pageContentItem.pageContents && pageContentItem.pageContents.length) {
                    return <Section_4 key={index} pageContentItem={pageContentItem} />
                  }
                case 5:
                  if (pageContentItem.pageContents && pageContentItem.pageContents.length) {
                    let currentPageContent = pageContentItem.pageContents[0];
                    return <Section_5 key={index} currentPageContent={currentPageContent} sectionClassName="pb-5" />
                  }
                case 6:
                  if (pageContentItem.pageContents && pageContentItem.pageContents.length) {
                    let currentPageContent = pageContentItem.pageContents[0];
                    return <Section_6 key={index} currentPageContent={currentPageContent} sectionClassName="py-3" />
                  }
                //
                default:
                  break;
              }
            })()
          })
          : null
      }

    </Auxiliary>
  )
}
