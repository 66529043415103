import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import linkedinWhite from "../../assets/icons/social-media/linkdin-white.svg";
import youtubeWhite from "../../assets/icons/social-media/youtube-white.svg";
import facebookWhite from "../../assets/icons/social-media/facebook-white.svg";
import twitterWhite from "../../assets/icons/social-media/twitter-white.svg";
import mainLogoWhiteEn from "../../assets/images/logo/main-logo-white-l.svg";
import mainLogoWhiteAm from "../../assets/images/logo/main-logo-white-am-l.svg";
import wodsLogo from "../../assets/images/logo/wods-logo.svg";
import mindalayLogo from "../../assets/images/logo/mindalay-logo.svg";
import { useSelector } from "react-redux";
import TranslationService from "../../services/TranslationService";
import useWindowSize from "../hooks/usewindowSize";

export default function Footer() {
	const windowSize = useWindowSize();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<footer className="ardy--primary-bkg-color footer">
			<div className="container-fluid">
				<div className="row">
					<div className="col-md-6">
						{/* comment-uncomment */}
						{/* <div className="footer-items-wrapper d-flex justify-content-md-start justify-content-center">
            <ul>
              <li className="footer-item"><Link to={`/${language}/about`}>{translationService.translate("TR_ABOUT_US")}</Link></li>
            </ul>
          </div> */}
						{/*  */}
						{/* comment-uncomment */}
						<div
							className={`footer-items-wrapper d-flex justify-content-md-start justify-content-center flex-wrap flex-xl-nowrap`}>
							<ul>
								<li className="footer-item">
									<Link to={`${language}/events`}>
										{translationService.translate("TR_EVENTS")}
									</Link>
								</li>
								<li className="footer-item">
									<Link to={`${language}/news`}>
										{translationService.translate("TR_NEWS")}
									</Link>
								</li>
								<li className="footer-item">
									<Link to={`${language}/contact`}>
										{translationService.translate("TR_CONTACTS")}
									</Link>
								</li>
							</ul>
							<ul>
								<li className="footer-item">
									<Link to={`${language}/faq`}>
										{translationService.translate("TR_FAQ")}
									</Link>
								</li>
								<li className="footer-item">
									<Link to={`/${language}/about`}>
										{translationService.translate("TR_ABOUT_US")}
									</Link>
								</li>
								<li className="footer-item">
									<Link to={`/${language}/career`}>
										{translationService.translate("TR_CAREERS")}
									</Link>
								</li>
							</ul>
						</div>
						{/*  */}
						<div className="social-media-wrapper mt-4">
							<ul className="d-flex align-items-center justify-content-md-start justify-content-center">
								<li className="social-media-item">
									<Link
										to="https://www.linkedin.com/company/ardyacademy/mycompany"
										target="_blank">
										<img src={linkedinWhite} alt="linkedin" />
									</Link>
								</li>
								<li className="social-media-item">
									<Link
										to="https://www.youtube.com/watch?v=vGwG19MMLY8&embeds_referring_euri=https%3A%2F%2Fardy.am%2F&embeds_referring_origin=https%3A%2F%2Fardy.am&source_ve_path=Mjg2NjY&feature=emb_logo"
										target="_blank">
										<img src={youtubeWhite} alt="youtube" target="_blank" />
									</Link>
								</li>
								<li className="social-media-item">
									<Link
										to="https://www.facebook.com/ardyacademyarmenia"
										target="_blank">
										<img src={facebookWhite} alt="facebook" />
									</Link>
								</li>
								{/* <li className="social-media-item"><Link to="#"><img src={twitterWhite} alt="twitter" /></Link></li> */}
							</ul>
						</div>
					</div>
					<div className="col-md-6 mt-md-0 mt-5 text-md-start text-center d-flex flex-column justify-content-end">
						<div className="bottom-gray-border pb-0 pb-md-3 d-flex flex-wrap align-items-end w-100 gap-4">
							<img
								className="ardy_footer_logo"
								src={language === "am" ? mainLogoWhiteAm : mainLogoWhiteEn}
								alt="main-logo"
							/>
							<img
								src={wodsLogo}
								className="ardy_footer_logo"
								alt="wods-logo"
							/>
						</div>
						<div className="">
							{/* <p className="text-white mt-2">{translationService.translate("TR_FOOTER_INFO_TEXT")}</p> */}
							<a href="https://mindalay.com/en" target="_blank">
								<div className="d-flex align-items-center justify-content-start mt-5 gap-1">
									<img src={mindalayLogo} alt="mindalay-logo" />
									{language === "am" ? (
										<span className="text-white ms-3 d-block">
											Ստեղծված է <b>Mindalay</b>-ի կողմից
										</span>
									) : (
										<span className="text-white ms-3 d-block">
											Powered by: <b>Mindalay</b>
										</span>
									)}
								</div>
							</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	) : null;
}
