import React from 'react'
import mainDecor from "./../../assets/images/decoration/main-decor.svg"
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'

export default function Skeleton_Section_3() {


  return <section className="section position-relative service-section">
    <div className="service-decoration">
      <img src={mainDecor} alt="/" />
    </div>
    <div className="container-fluid">
      <div className="row d-flex position-relative">
        <div className="col-md-6">
          <div className="service-info-wrapper">
            <SkeletonComponent
              images={0}
              descs={3}
              titleHeight={60}
              imageHeight={400}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="service-info-wrapper">
            <SkeletonComponent
              images={0}
              descs={3}
              titleHeight={60}
              imageHeight={400}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="service-info-wrapper">
            <SkeletonComponent
              images={0}
              descs={3}
              titleHeight={60}
              imageHeight={400}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="service-info-wrapper">
            <SkeletonComponent
              images={0}
              descs={3}
              titleHeight={60}
              imageHeight={400}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="service-info-wrapper">
            <SkeletonComponent
              images={0}
              descs={3}
              titleHeight={60}
              imageHeight={400}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="service-info-wrapper">
            <SkeletonComponent
              images={0}
              descs={3}
              titleHeight={60}
              imageHeight={400}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
}
