import React from 'react'

export default function PlaySvg() {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.36441 3C8.26502 1.73631 10.5465 1 13 1C19.6274 1 25 6.37258 25 13C25 19.6274 19.6274 25 13 25C6.37258 25 1 19.6274 1 13C1 10.6046 1.70186 8.37312 2.91116 6.5" stroke="#ffffff" strokeWidth="2" />
      <path d="M10.5 19.0623L17.0378 13.7779C17.533 13.3776 17.533 12.6227 17.0378 12.2225L12.1286 8.25438C11.4747 7.72581 10.5 8.19125 10.5 9.0321L10.5 13.5" stroke="#ffffff" strokeWidth="2" />
    </svg>
  )
}
