import React from 'react'
import Layout from "./Layout"
import { ToastContainer } from "react-toastify"
import Translation from './Translation';
import Language from "./Language"
// import SpinnerComponent from './components/spinners/SpinnerComponent';
import Helmet from './Helmet';

export default function App() {

  return (
    <React.Fragment>
      <Language />
      <Translation />
      <Layout />
      <ToastContainer autoClose={5000} hideProgressBar={true} icon={false} />
      {/* <SpinnerComponent /> */}
      <Helmet />
    </React.Fragment>
  )
}
