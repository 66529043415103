import React from 'react'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent';

export default function Skeleton_Section_2({ sectionClassName = "" }) {

  return <section className={`section position-relative ${sectionClassName}`}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-5">
          <div className='ardy--title-wrapper ardy--text-white-wrapper'>
            <SkeletonComponent
              images={0}
              descs={3}
              titleHeight={150}
            />
          </div>
        
        </div>
        <div className="col-md-7">
          <div className="ardy--building-image-wrapper">
            <SkeletonComponent
              titles={0}
              descs={0}
              imageHeight={400}
            />
          </div>
        </div>
      </div>
    </div>
  </section>
}
