import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import userReducer from './user';
import translationReducer from './translation';
import languageReducer from './language';
import mainReducer from './main';
import spinnerReducer from './spinner';

const store = configureStore({
  reducer: {
    user: userReducer,
    translation: translationReducer,
    language: languageReducer,
    main: mainReducer,
    spinners: spinnerReducer,
  },
  middleware: [thunkMiddleware]
});

export default store;
