import React, { useCallback, useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import ApiService from '../../services/ApiService'
import { addSpinner, removeSpinner } from '../../store/actions/spinner'
import AlertService from '../../services/AlertService'
import { v4 as uuidv4 } from 'uuid'
import TranslationService from '../../services/TranslationService';
import Auxiliary from '../../hoc/Auxiliary'
import Section_1 from '../sections/Section_1'
import arrowRightLight1 from "./../../assets/icons/arrow/arrow-right-light-l.svg"
import ReactPlayer from 'react-player'
import useWindowSize from '../../components/hooks/usewindowSize'
import Skeleton_Section_1 from '../skeletonsSections/Skeleton_Section_1'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import Section_7 from '../sections/Section_7'

export default function Specialization() {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
  const { language } = useSelector(state => state.language);
  const { spinners } = useSelector(state => state.spinners);
  const [pageContent, setPageContent] = useState([]);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const [freeCoursesByFaculties, setFreeCoursesByFaculties] = useState([]);
  const [selectedFaculty, setSelectedFaculty] = useState(null);

  let interval = 0;

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (selectedFaculty) {
      videoRatio();
    }
  }, [windowSize, language, selectedFaculty]);

  const videoRatio = () => {
    clearInterval(interval);
    const element = document.querySelector(".ardy--section-video-block");
    if (element) {
      element.style.height = element?.offsetWidth * 9 / 16 + 'px';
    } else {
      interval = setInterval(() => {
        videoRatio();
      }, 500);
    }
  };

  useEffect(() => {
    if (language) {
      getPageContentByName();
      getFreeCoursesByFaculties();
    }
    return () => {
      setPageContent([]);
      setFreeCoursesByFaculties([]);
    }
  }, [language])

  const getPageContentByName = () => {
    let spinnerId = uuidv4();
    setSpinner(spinnerId);
    ApiService.getPageContentByName("specialization").then(response => {
      if (response && response.data) {
        setPageContent(response.data);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const getFreeCoursesByFaculties = () => {
    let spinnerId = uuidv4();
    setSpinner(spinnerId);
    ApiService.getFreeCoursesByFaculties().then(response => {
      if (response && response.data) {
        setFreeCoursesByFaculties(response.data);
        if (response.data[0].facultyInfo) {
          setSelectedFaculty(response.data[0].facultyInfo)
        }
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    setTimeout(() => {
      dispatch(removeSpinner(spinner));
    }, 500);
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert("error", error);
    spinnerId && extractSpinner(spinnerId);
  }

  if (spinners && spinners.length) {
    return <div className=''>
      <section className="mt-5 position-relative">
        <SkeletonComponent
          descs={0}
          images={0}
          titleHeight={70}
          maxWith='500px'
        />
        <Skeleton_Section_1 sectionClassName="pt-3" />
        <SkeletonComponent
          titles={0}
          descs={0}
          imageHeight={400}
        />

        <SkeletonComponent
          descs={0}
          images={0}
          titleHeight={70}
          maxWith='500px'
        />
        <SkeletonComponent
          descs={0}
          images={0}
          titleHeight={80}
          maxWith='800px'
        />
        <div className='container-fluid'>

          <div className='row'>
            <div className={`col-md-7 mb-5 mb-md-0`}>
              <div className="cover-ardy--section-image-wrapper d-flex justify-content-end" style={{ height: "400px", minHeight: "400px" }}>
                <SkeletonComponent
                  titles={0}
                  descs={0}
                  imageHeight={400}
                />
              </div>
            </div>
            <div className="col-md-5">
              <div className='ardy--title-wrapper'>
                <SkeletonComponent
                  images={0}
                  descs={4}
                  titleHeight={150}
                />
                <SkeletonComponent
                  images={0}
                  descs={0}
                  titleHeight={50}
                  maxWith='50%'
                  position='left'
                />
              </div>
            </div>
          </div>
          <div className='row mt-5'>
            <div className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-4 mb-0">
              <SkeletonComponent
                titles={0}
                descs={0}
                imageHeight={300}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-4 mb-0">
              <SkeletonComponent
                titles={0}
                descs={0}
                imageHeight={300}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-4 mb-0">
              <SkeletonComponent
                titles={0}
                descs={0}
                imageHeight={300}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-4 mb-0">
              <SkeletonComponent
                titles={0}
                descs={0}
                imageHeight={300}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-4 mb-0">
              <SkeletonComponent
                titles={0}
                descs={0}
                imageHeight={300}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-4 mb-0">
              <SkeletonComponent
                titles={0}
                descs={0}
                imageHeight={300}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-4 mb-0">
              <SkeletonComponent
                titles={0}
                descs={0}
                imageHeight={300}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-4 mb-0">
              <SkeletonComponent
                titles={0}
                descs={0}
                imageHeight={300}
              />
            </div>
          </div>
        </div>


      </section>
    </div>
  }

  return translationService ?
    <Auxiliary>
      <div className='ardy--title-wrapper'>
        <h1 className='text-center my-5'>
          {translationService.translate("TR_SPECIALIZATIONS")}
        </h1>
      </div>
      {
        pageContent && pageContent.length ?
          pageContent.map((pageContentItem, index) => {
            return (() => {
              switch (pageContentItem.sectionType) {
                case 1:
                  if (pageContentItem.pageContents && pageContentItem.pageContents.length) {
                    let currentPageContent = pageContentItem.pageContents[0];
                    return <>
                      <Section_1 key={index} currentPageContent={currentPageContent} sectionClassName="pt-0" />
                    </>
                  }
                case 7:
                  if (pageContentItem.pageContents && pageContentItem.pageContents.length) {
                    let currentPageContent = pageContentItem.pageContents[0];
                    return <Section_7 key={index} currentPageContent={currentPageContent} sectionClassName="py-70" />
                  }
                default:
                  break;
              }
            })()
          })
          : null
      }

      {
        freeCoursesByFaculties && freeCoursesByFaculties.length ?
          <section className="position-relative mt-5">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="text-center py-5 max-w-700 m-auto">
                    <h1 className="h1">{translationService.translate("TR_INDUSTRIES")}</h1>
                    <div className="btn-group segmented--btn mt-5 d-flex justify-content-between flex-wrap" role="group" aria-label="Basic example">
                      {
                        freeCoursesByFaculties.map((item, index) => {
                          return <button
                            key={index}
                            type="button"
                            title={item.facultyInfo?.title}
                            className={`button text-nowrap max-line-1 ardy--choose-btn ${selectedFaculty?.id === item.facultyInfo?.id ? "active" : ""}`}
                            style={{ width: Math.round(100 / freeCoursesByFaculties.length) + "%" }}
                            onClick={() => setSelectedFaculty(item.facultyInfo)}
                          >
                            {item.facultyInfo?.title}
                          </button>
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          : null
      }
      {
        selectedFaculty ?
          <section className={`section position-relative pb-0`}>
            {/* <img className="cover-decoration" src={mainDecor} alt="/" /> */}
            <div className="container-fluid">
              <div className="row align-items-center mb-5">
                <div className="col-md-6 mb-5 mb-md-0">
                  <div
                    className="ardy--section-video-block"
                    style={{ maxWidth: "100%" }}
                  >
                    <ReactPlayer
                      controls={true}
                      className="ardy--custom-section-video-player ardy--primary-box-shadow w-auto h-100"
                      url={selectedFaculty?.mainVideoLink || ""}
                    />
                  </div>
                </div>

                <div className="col-md-6 ardy--paddingX-block">
                  <div className='d-flex'>
                    <div>
                      <img className={`me-2 mt-md-3 mt-1`} src={selectedFaculty.logoPath} width={32} height={32} style={{ filter: "invert(1)" }} />
                    </div>
                    <div>
                      <div className='ardy--title-wrapper'>
                        <h2><strong>{selectedFaculty.title}</strong></h2>
                      </div>
                      <p>{selectedFaculty.description}</p>

                      <button
                        type="button"
                        className="button primary--btn arrow--btn mt-4 d-block"
                        onClick={() => {
                          if (selectedFaculty.applyLink) {
                            window.open(selectedFaculty.applyLink, "_blank")
                          }
                        }}
                      >
                        {translationService.translate("TR_LABEL")}
                        <img src={arrowRightLight1} alt="/" />
                      </button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </section>
          : null
      }
      {
        selectedFaculty.videoLinks && selectedFaculty.videoLinks.length ?

          <section className="section pt-0">
            <div className="container-fluid">
              <div className="row align-self-stretch">

                {
                  selectedFaculty.videoLinks.map((item, index) => {
                    return <div key={index} className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-4 mb-0">
                      <div className="ardy--card ardy--cursor-pointer h-100">
                        <div
                          style={{ maxWidth: "100%", }}
                        >
                          <ReactPlayer
                            controls={true}
                            className="w-auto h-100"
                            url={item.videoLink || ""}
                          />
                        </div>
                        <div style={{ cursor: "default", padding: "8px 16px" }}>
                          <h3 className="h3 ">{item.videoTitle}</h3>
                        </div>
                      </div>
                    </div>
                  })
                }

              </div>
            </div>
          </section>
          : null
      }
    </Auxiliary>
    : null
}
