import React, { useState } from 'react'
import mainDecor from "./../../assets/images/decoration/main-decor.svg"
import { useSelector } from 'react-redux';
import useWindowSize from '../../components/hooks/usewindowSize';

export default function Page_404() {

  const { language } = useSelector(state => state.language);
  const windowSize = useWindowSize();


  return (
    <section className="section-top position-relative">
      <img className="cover-profile-decoration" src={mainDecor} alt="/" />
      <div className="container-fluid">
        <div className='fixed-center'>
          <div>
            <h1 style={{ fontSize: "150px", fontWeight: "300", textAlign:"center" }}>404</h1>
            <p style={{ fontSize: "20px", textAlign: "center", lineHeight:"30px" }}>
              Oh, this page somehow can't be found.
              <br />
              We are working on this. Come back soon.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
