import { SET_IS_SHOW_LOGIN_MODAL } from "../../constants/reduxKeys";

let initialState = {
  isShowLoginModal: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_SHOW_LOGIN_MODAL:
      return {
        ...state,
        isShowLoginModal: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;