import React, { useCallback, useEffect, useState } from "react";
import Auxiliary from "../../hoc/Auxiliary";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ApiService from "../../services/ApiService";
import TranslationService from "../../services/TranslationService";
import { v4 as uuidv4 } from "uuid";
import { addSpinner, removeSpinner } from "../../store/actions/spinner";
import SkeletonComponent from "../../components/Skeleton/SkeletonComponent";
import Parser from "html-react-parser";
import AlertService from "../../services/AlertService";
import mainDecor from "./../../assets/images/decoration/main-decor.svg";

function News() {
	const { language } = useSelector(state => state.language);
	const dispatch = useDispatch();
	const pageSize = 10;
	const { spinners } = useSelector(state => state.spinners);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [news, setNews] = useState([]);
	const [firstNews, setFirstNews] = useState(null);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(1);
	const navigate = useNavigate();

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		setActivePageNumber(1);
		getNews(1);
	}, [language]);

	const handlePageClick = () => {
		setActivePageNumber(activePageNumber + 1);
		getNews(activePageNumber + 1, true);
	};

	const getNews = (activePageNumber, add) => {
		let spinnerId = uuidv4();
		setSpinner(spinnerId);
		ApiService.getNews(activePageNumber, pageSize)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				if (response && response.data) {
					if (add) {
						const data = [...news, ...response.data];
						setFirstNews(data[0]);
						setNews(data);
					} else {
						setFirstNews(response.data[0]);
						setNews(response.data);
					}
				}
				extractSpinner(spinnerId);
				scrollToTop();
			})
			.catch(error => getFail(error, spinnerId));
	};

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	const getFail = (error, spinnerId) => {
		error && AlertService.alert("error", error);
		spinnerId && extractSpinner(spinnerId);
	};

	const setSpinner = useCallback(spinner => {
		dispatch(addSpinner(spinner));
	}, []);

	const extractSpinner = useCallback(spinner => {
		setTimeout(() => {
			dispatch(removeSpinner(spinner));
		}, 500);
	}, []);

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	if (spinners && spinners.length) {
		return (
			<section className="section">
				<div className="container-fluid overflow-hidden p-0">
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={300}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={400}
								titles={0}
								descs={0}
							/>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
						<div className="col-md-4">
							<SkeletonComponent
								rows={1}
								cols={1}
								imageHeight={700}
								titles={0}
								descs={0}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-8">
							<div className="mt-5">
								<SkeletonComponent
									images={0}
									titleHeight={60}
									descs={5}
									imageHeight={400}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="ps-5">
								<SkeletonComponent
									rows={1}
									titles={0}
									imageHeight={400}
									descs={0}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		);
	}

	return (
		translationService &&
		firstNews &&
		news && (
			<Auxiliary>
				<section className="section pb-0 position-relative">
					<img className="cover-decoration" src={mainDecor} alt="/" />
					<div className="container-fluid">
						<div className="text-center pb-5 max-w-700 m-auto">
							<h1 className="h1">
								<b>{translationService.translate("TR_NEWS")}</b>
							</h1>
						</div>
						<div className="row padding_none p-0 m-0">
							<div
								className="col-12 shadow rounded overflow-hidden"
								style={{
									backgroundColor: "#D8D8D8",
								}}>
								<div className="d-flex flex-lg-row flex-column">
									{firstNews && !!firstNews.imagePaths.length && (
										<Link
											className="ardy_news_image"
											style={{
												backgroundImage: `url(${firstNews.imagePaths[0]})`,
											}}
											to={`/${language}/news/${firstNews.id}`}></Link>
									)}

									<div className="ardy_news_information  px-2 py-lg-0 pb-5">
										<div className="time">
											<span>
												{capitalizeFirstLetter(
													moment(new Date(firstNews?.createDate))
														.locale(language)
														.fromNow(),
												)}
											</span>
											<div className="line" />
										</div>
										{firstNews && !!firstNews.title && (
											<Link
												className=""
												to={`/${language}/news/${firstNews.id}`}>
												<h1 className="h1">{firstNews.title}</h1>
											</Link>
										)}
										{firstNews && !!firstNews.shortDescription && (
											<div className="text max-line-3">
												{Parser(firstNews.shortDescription)}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className="section">
					<div className="container-fluid">
						<div className="row align-self-stretch">
							{news && !!news.length && (
								<>
									<div className="col-12 mb-1">
										<h3 className="h3 fw-500">
											{translationService.translate("TR_RECENTS")}
										</h3>
									</div>
									<div className="row align-self-stretch">
										{news.map((item, index) => {
											if (firstNews && firstNews.id !== item.id) {
												return (
													<div
														key={index}
														className="col-12 col-md-6 col-lg-3 mb-md-0 mb-3 mb-md-3 mb-0">
														<Link
															className=""
															to={`/${language}/news/${item.id}`}>
															<div className="ardy--card ardy--cursor-pointer h-100 shadow-none">
																<div
																	className="ardy--card-image ardy--image-background"
																	style={{
																		backgroundImage: `url(${item.imagePaths[0]})`,
																	}}></div>
																<div className="ardy--card-body align-items-baseline  d-block">
																	<div className="time">
																		<span className="first_letter_uppercase d-block mt-1 mb-2">
																			{capitalizeFirstLetter(
																				moment(item.createDate)
																					.locale(language)
																					.fromNow(),
																			)}
																		</span>
																	</div>
																	{/* {getDate(item.schedule)} */}
																	<h3
																		className="h3 mb-0 max-line-3 fw-400"
																		style={{ lineHeight: "30px" }}
																		title={item.title}>
																		{item.title}
																	</h3>
																</div>
															</div>
														</Link>
													</div>
												);
											}
										})}
									</div>
								</>
							)}
							{pagination && pagination.TotalCount === news.length ? null : (
								<div className="col-12">
									<button
										type="button"
										className="button mt-3 w-100 bg-white"
										style={{
											borderRadius: "16px",
											paddingTop: "22px",
											paddingBottom: "22px",
										}}
										onClick={handlePageClick}>
										{translationService.translate("TR_MORE")}
									</button>
								</div>
							)}
						</div>
					</div>
				</section>
			</Auxiliary>
		)
	);
}

export default News;
