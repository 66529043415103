import { DEFAULT_LANGUAGE_KEY, LANGUAGE_KEY, LANGUAGES_KEY } from "../../constants/mainKeys";
import { GET_LANGUAGES_SUCCESS, LANGUAGE_SUCCESS } from "../../constants/reduxKeys";

let initialState = {
  languages: [],
  language: localStorage.getItem(LANGUAGE_KEY) && localStorage.getItem(LANGUAGE_KEY) !== (null || "null") ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LANGUAGE_SUCCESS:
      
      return {
        ...state,
        language: action.payload,
      };
    case GET_LANGUAGES_SUCCESS:
      localStorage.setItem(LANGUAGES_KEY, JSON.stringify(action.payload))
      return {
        ...state,
        languages: action.payload
      };
    default:
      break;
  }
  return state;
};

export default reducer;