import React from "react";
import { useState } from "react";
import AlertService from "../../../services/AlertService";
import ApiService from "../../../services/ApiService";

export default function ContactUsForm({ translationService }) {
	const [isDisabledButton, setIsDisabledButton] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [isDisabledFields, setIsDisabledFields] = useState(false);

	const [values, setValues] = useState({
		name: "",
		email: "",
		phoneNumber: "",
		description: "",
	});

	const onChange = (event, name, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		setValues(prevState => ({
			...prevState,
			[name]: event.target.value,
		}));
		setIsInvalidSubmit(false);
	};

	const onSubmit = event => {
		event && event.preventDefault();
		setIsDisabledFields(true);
		ApiService.createCorporateCourseApplication(values)
			.then(response => {
				AlertService.alert("success", "Data saved");
				setIsDisabledFields(false);
				setValues({
					name: "",
					email: "",
					phoneNumber: "",
					description: "",
				});
			})
			.catch(error => getFail(error));
	};
	const getFail = error => {
		error && AlertService.alert("error", error);
		setIsDisabledFields(false);
	};

	return (
		<section className="section pt-0">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12">
						<div className="text-center max-w-700 m-auto">
							<div className="text-start word-break-break-word">
								<form onSubmit={onSubmit}>
									<h2 className="h2-m mb-4">
										{translationService.translate("TR_SEND_US_A_REQUEST")}
									</h2>
									<div className="mb-4">
										<input
											type="text"
											className="form-control"
											placeholder={`${translationService.translate(
												"TR_NAME",
											)} ${translationService.translate("TR_SURNAME")}`}
											value={values.name}
											onChange={event => onChange(event, "name", 100)}
										/>
									</div>
									<div className="mb-4">
										<input
											type="email"
											className="form-control"
											placeholder={translationService.translate(
												"TR_YOUR_EMAIL",
											)}
											value={values.email}
											onChange={event => onChange(event, "email", 100)}
										/>
									</div>
									<div className="mb-4">
										<small className="mb-1" style={{ fontWeight: "700" }}>
											{translationService.translate("TR_START_WITH_PLUS")}
										</small>
										<input
											type="text"
											className="form-control"
											placeholder="+374 xx xxx xxx"
											value={values.phoneNumber}
											onChange={event => onChange(event, "phoneNumber", 20)}
										/>
									</div>
									<div className="mb-4">
										<small className="mb-1" style={{ fontWeight: "700" }}>
											{translationService
												.translate("TR_CHARACTER_LIMIT")
												.replace("{0}", 300)}
										</small>
										<textarea
											className="w-100"
											style={{ resize: "none" }}
											placeholder={translationService.translate(
												"TR_TELL_US_ABOUT_YOURSELF",
											)}
											rows="5"
											value={values.description}
											onChange={event => onChange(event, "description", 300)}
										/>
									</div>
									<div className="d-flex justify-content-end">
										<button
											type="submit"
											className={`
                        button primary--btn py-2
                         ${
														isDisabledButton ||
														!values.name.trim().length ||
														!values.email.trim().length ||
														!values.phoneNumber.trim().length ||
														!values.description.trim().length ||
														isDisabledFields
															? "disabled--btn pe-none"
															: ""
													}`}>
											{translationService.translate("TR_SEND")}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
